import { Flex, Heading, Text } from "@radix-ui/themes";
import WizardProgressBar from "./wizard-progress-bar";

export default function WizardHeader({ previousRoute, percentage, name, subtitle }) {    
    return (<>        
        <WizardProgressBar previousRoute={previousRoute} percentage={percentage} />        
        <Heading size="6" align="center" mt="3">{ name }</Heading>                        
        { subtitle && <Text align="center" style={{ width: '80%' }}>{ subtitle }</Text> }        
    </>);
}
