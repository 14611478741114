import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import {  Heading, Text, Em } from '@radix-ui/themes';
import { CenteredLayout } from "@/layouts";
import { useTranslation } from "react-i18next";

export default function ErrorPage() {
  const { t } = useTranslation();
  const error = useRouteError();
      
  const message = (isRouteErrorResponse(error) && error.status === 404) ? 'error_not_found' : 'error_unexpected';    
  const explanation = (isRouteErrorResponse(error)) ? error.statusText : error.message;
  
  return (
    <CenteredLayout>
      <Heading size="8" mb="5">{ t('error_page_title') }</Heading>
      <Text mb="2">{ t(message) }</Text>
      <Text>
        <Em>{ explanation }</Em>
      </Text>
    </CenteredLayout>
  );
}