import { Box, Button, Container, Flex } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CookieConsent() {
    const { t } = useTranslation();
    
    const [cookiePreferences, setCookiePreferences] = useState(localStorage.getItem('cookiePreferences'));    

    function grantAllConsent() {        
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        });
    }

    useEffect(() => {
        if (cookiePreferences === 'all') {
            grantAllConsent();
        }
    }, [cookiePreferences]);

    if (cookiePreferences) {
        return null;
    }

    function deny() {
        localStorage.setItem('cookiePreferences', 'only-necessary');
        setCookiePreferences('only-necessary');        
    }

    function acceptAll() {
        localStorage.setItem('cookiePreferences', 'all');
        setCookiePreferences('all');
        grantAllConsent();        
    }


    return (
        <Box p="6" id="cookie-consent" style={{ position: 'fixed', bottom: 0, right: 0, left: 0, zIndex: 99999, backgroundColor: '#fff' }}>
            <Container size="3">
                <Box id="cookie-consent-text" mb="5">
                   { t('cookies_text') } <a href='/privacy-policy'>{ t('cookies_privacy_policy') }</a>          
                </Box>
                <Flex gap="2" justify="end">
                    <Button onClick={deny} id="cookie-consent-button" color="gray" radius="full" variant="outline" size="3">{ t('button_deny') }</Button>
                    <Button onClick={acceptAll} id="cookie-consent-button" size="3" radius="full">{ t('button_accept_all') }</Button>                
                </Flex>
            </Container>
        </Box>
    );
}
