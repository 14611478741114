import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from "react-router-dom";
import ErrorPage from './pages/error-page'
import WelcomePage from './pages/welcome-page'
import SignInPage from './pages/sign-in-page'
import { Theme } from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';
import './index.scss'
import VerifyEmailPage from './pages/verify-email-page';
import RegistrationPage from './pages/registration-page';
import RegistrationCompletePage from './pages/registration-complete-page';
import BasicInfoPage from './pages/basic-info-page';
import BasicInfoCompletePage from './pages/basic-info-complete-page';
import LifestylePage from './pages/lifestyle-page';
import LifestyleCompletePage from './pages/lifestyle-complete-page';
import FollowUpPage from './pages/follow-up-page';
import FollowUpCompletePage from './pages/follow-up-complete-page';
import EmailVerifiedPage from './pages/email-verified-page';

// Do not remove, required for localization to work
import i18n from './i18n'
import { Provider } from 'react-redux';
import store from './store';
import RequireUser from './components/require-user';
import JokesPage from './pages/jokes-page';
import ChatsPage from './pages/chats-page';
import ChatPage from './pages/chat-page';
import ConnectionError from './components/connection-error';
import NavigateToLastStepPage from './pages/navigate-to-last-step-page';
import SignInFacebookPage from './pages/sign-in-facebook-page';
import SignInGooglePage from './pages/sign-in-google-page';
import GeoLocationPage from './pages/geo-location-page';
import TermsOfServicePage from './pages/terms-of-service-page';
import PrivacyPolicyPage from './pages/privacy-policy-page';
import CookieConsent from './components/cookie-consent';

import * as Sentry from "@sentry/react";
import './firebase-globals';

Sentry.init({
  dsn: "https://a82f28a3d33962c17311ac66542ccc25@o4506945782677504.ingest.us.sentry.io/4506945784446976",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/www.friendify.io\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <RequireUser />,

    // private routes
    children: [
      {
        path: "",
        element: <NavigateToLastStepPage />,
      },
      {
        path: "/registration/:index?",
        element: <RegistrationPage />,
      },
      {
        path: "/registration-complete",
        element: <RegistrationCompletePage />,
      },
      {
        // This one requires special layout
        path: "/basic-info/4",
        element: <GeoLocationPage />,
      },
      {
        path: "/basic-info/:index?",
        element: <BasicInfoPage />,
      },      
      {
        path: "/basic-info-complete",
        element: <BasicInfoCompletePage />,
      },
      {
        path: "/lifestyle",
        element: <LifestylePage />,
      },
      {
        path: "/lifestyle-complete",
        element: <LifestyleCompletePage />,
      },
      {
        path: "/follow-up/:index?",
        element: <FollowUpPage />,
      },
      {
        path: "/follow-up-complete",
        element: <FollowUpCompletePage />,
      },
      {
        path: "/jokes",
        element: <JokesPage />,
      },
      {
        path: "/chats",
        element: <ChatsPage />,
      },
      {
        path: "/chats/:id",
        element: <ChatPage />,
      }
    ],
    errorElement: <ErrorPage />,
  },

  // public routes
  {
    path: "/welcome",
    element: <WelcomePage />,
  },
  {
    path: "/sign-in",
    element: <SignInPage />,
  },
  {
    path: "/sign-in/facebook",
    element: <SignInFacebookPage />,
  },
  {
    path: "/sign-in/google",
    element: <SignInGooglePage />,
  },
  {
    path: "/verify-email",
    element: <VerifyEmailPage />,
  },
  {
    path: "/email-verified/:code",
    element: <EmailVerifiedPage />,
  },
  {
    path: "/terms-of-service",
    element: <TermsOfServicePage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <Theme>
        <RouterProvider router={router} />
        <ConnectionError />
        <CookieConsent />
      </Theme>
    </Provider>
  </React.StrictMode>,
)
