import { VotableContent } from "@/components";
import { useGetLifestyleCounterQuery, useGetUnvotedLifestyleQuery, useVoteLifestyleMutation } from "@/api/lifestyleApi";
import { assetsUrl } from "@/utils/assets";


export default function LifestylePage() {    
    return (
        <VotableContent 
            id="lifestyle"
            getCounterHook={useGetLifestyleCounterQuery} 
            getUnvotedHook={useGetUnvotedLifestyleQuery} 
            voteHook={useVoteLifestyleMutation} 
            renderContent={(content) => <ImageContent content={content} /> }
            completeUrl='/lifestyle-complete'
            fastForwardCompleteUrl='/follow-up?ff=1' 
        />        
    );
}


function ImageContent({ content }) {
    const imageSrc = assetsUrl('/images/' + content.filename);
    return <img src={ imageSrc } style={{ width: '100%', height: '100%', objectFit: 'cover' }} />;
}
