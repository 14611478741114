import { createSlice } from "@reduxjs/toolkit";

export const connectionSlice = createSlice({
  name: "connection",
  initialState: {    
    noConnection: false,
    retrying: false,
    error: null
  },
  reducers: {
    retry(state, action) {
      state.retrying = true;
    },

    clearError(state, action) {
      state.retrying = false;
      state.error = null;
    },

    error(state, action) {
      state.retrying = false;
      state.error = {
        message: action.payload.message,
        retry: action.payload.retry
      }
    },

    connect(state, action) { 
      state.retrying = true;
    },

    disconnected(state, action) {
      state.retrying = false;
      state.noConnection = true;
    },

    connected(state, action) {
      state.noConnection = false;
      state.retrying = false;
    },

    disconnect(state, action) { },
    reconnect(state, action) {
      state.retrying = true;
    },

    invalidAccessToken(state, action) {}
  }
});

export const {
  retry,
  clearError,
  error,
  disconnected,
  connect,
  connected,
  disconnect,
  reconnect,
  invalidAccessToken,
} = connectionSlice.actions;
