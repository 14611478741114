import './choice.scss';
import './unique-choice.scss';
import { Text } from "@radix-ui/themes";
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function UniqueChoice({ choices, value, valueChange, ...props }) {
    const { t } = useTranslation();

    useEffect(() => {
        if (value == null || value == '') {
            valueChange(choices[0]);                           
        }
    }, [value, choices]);

    return (
        <RadioGroup.Root value={value} className="ChoiceGroupRoot" onValueChange={valueChange}>            
            { choices.map((choice, index) => (                    
                <RadioGroup.Item className="ChoiceGroupItem" key={choice} value={choice}>                                            
                    <Text>{ props.translations ? props.translations[index] : t('choice_' + choice) }</Text>
                    <div className="ChoiceIndicatorOutline">
                        <RadioGroup.Indicator className="UniqueChoiceIndicator" />
                    </div>
                </RadioGroup.Item>
            )) }            
        </RadioGroup.Root>
    );
}