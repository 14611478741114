import { TextField, UniqueChoice } from '@/components';
import { alwaysValid, notEmptyString } from '@/validators';
import WizardBase from '@/components/wizard-base';
import { useGetProfileQuery, useUpdateProfileMutation } from '@/api/profileApi';
import { useTranslation } from 'react-i18next';

const privateSubtitle = 'subtitle_private';
const steps = [
    { name: 'career', subtitle: privateSubtitle, content: (p) => <Career {...p} />, validator: notEmptyString },
    { name: 'family', subtitle: privateSubtitle, content: (p) => <Family {...p} />, validator: notEmptyString },    
    { name: 'kids', subtitle: privateSubtitle, content: (p) => <Kids {...p} />, validator: notEmptyString },    
    { name: 'education', subtitle: privateSubtitle, content: (p) => <Education {...p} />, validator: notEmptyString },    

    // This one has a custom page
    { name: 'geolocation' },
];

export const stepNames = steps.map(step => step.name);

export default function BasicInfoPage() {
    return <WizardBase 
        name="basic-info"
        steps={steps}
        getHook={useGetProfileQuery} 
        patchHook={useUpdateProfileMutation} 
    />;    
}

function Career(props) {
    return <UniqueChoice choices={['student', 'young_professional', 'senior_professional', 'business_owner', 'unemployed', 'stay_at_home', 'retired']} {...props} />;
}

function Family(props) {
    return <UniqueChoice choices={['single', 'married', 'in_a_relationship', 'divorced', 'widowed']} {...props} />;
}

function Kids(props) {
    const { t } = useTranslation();
    // TODO: Do not allow smaller than 0, and do not allow decimals (currently doesn't work properly)    
    return <TextField placeholder={t('placeholder_kids')} type="number" min="0" step="1" {...props} />;
}

function Education(props) {
    return <UniqueChoice choices={['elementary_school', 'high_school', 'university']} {...props} />;
}