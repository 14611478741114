import { Badge } from "@radix-ui/themes";


export default function NotificationCount({ count, size, offset=true }) {
    if (count === 0) {
        return undefined;
    }    

    const badgeSize = size + 'px';

    return (
        <Badge style={{ 
            padding: '0', 
            width: badgeSize, 
            height: badgeSize, 
            textAlign: 'center', 
            fontWeight: 'bold', 
            lineHeight: badgeSize, 
            display: 'block', 
            position: 'relative', 
            top: offset ? '-6px' : '0',
            right: size > 40 ? '-6px' : '0'
        }} variant="solid" radius="full" size="1">{count}</Badge>
    );
}
