import { useGetChatsQuery } from "@/api/chatApi";
import { useGetProfileQuery } from "@/api/profileApi";
import { ChatCard, FullScreenLoader, FullScreenLoadingError } from "@/components";
import { Box, Flex, Heading } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";
import ProfileButton from "./profile-button";
import { useEffect } from "react";
import { requestAndStoreMessagingToken } from "@/middlewares/notification-token-middleware";
import { useSearchParams } from "react-router-dom";

export default function ChatList(props) {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: profile, isLoading: isLoadingProfile } = useGetProfileQuery();
    const { data, isLoading: isLoadingChats, refetch } = useGetChatsQuery();

    useEffect(() => {
        if (searchParams.get('refresh')) {
            setSearchParams({});
            refetch();            
        }
    }, [searchParams.get('refresh')]);

    const isLoading = isLoadingProfile || isLoadingChats;    

    useEffect(() => {
        async function handlePermissions() {
            if (Notification.permission === 'default') {
                try {
                    await requestAndStoreMessagingToken();
                } catch (error) { }    
            }
        }

        // TODO: This is ugly and should be refactored
        if (!isLoading && data != null && data.results.length > 0 && props.emptyList) {
            handlePermissions();
        }
    }, [isLoading]);

    if (isLoading) {
        return <FullScreenLoader />;
    }

    if (data == null) {
        return <FullScreenLoadingError onRetry={refetch} />;
    }

    const currentUserId = profile.id;    

    function filterSelf(users) {
        return users.filter((user) => user.id !== currentUserId);
    }
     
    if (data.results.length === 0 && props.emptyList) {
        return <>
            <ProfileButton user={profile} topRightCorner={true} />
            { props.emptyList }
        </>;
    }

    const style = props.bordered ? { borderRight: '1px solid var(--gray-a5)', borderLeft: '1px solid var(--gray-a5)' } : {};

    return (
        <Flex direction="column" align="center" width="100%" height="100%" style={{ maxWidth: '550px', maxHeight: '100%', margin: '0 auto', ...style }}>
            <Flex gap="1" style={{ marginTop: '16px', marginBottom: '2px' }}>
                <Heading size="6">{t('title_matches')}</Heading>                
            </Flex>
            <Flex mt="4" direction="column" width="100%" style={{ flexGrow: 1, height: 'auto', maxHeight: '100%', overflowY: 'scroll' }}>
                {data.results.map((chat) => (                    
                    <ChatCard key={chat.id} user={filterSelf(chat.users)[0]} chat={chat} active={props.active == chat.id} />
                ))}
                <Box />                
            </Flex>
            <Flex width="100%" align="end">
                <Box p="5" style={{ borderTop: '1px solid var(--gray-a5)' }} width="100%">
                    <ProfileButton user={profile} displayName={true} />
                </Box>
            </Flex>
        </Flex>
    );
}
