import { Button, WizardProgressBar } from "@/components";
import { TopLayout } from "@/layouts";
import { Flex, Heading, Text } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";
import Location from "@/assets/location.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUpdateProfileMutation } from "@/api/profileApi";
import { analytics } from "@/firebase-globals";
import { logEvent } from "firebase/analytics";

export default function GeoLocationPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [mutateProfile, updateResult] = useUpdateProfileMutation();

    useEffect(() => {
        if (updateResult.isSuccess) {            
            navigate('/basic-info-complete');
        }        
        if (updateResult.isError) {
            console.log(updateResult.error);            
            // TODO: Better error handling / maybe some toast?
        }
    }, [updateResult]);

    useEffect(() => {
        if (!navigator.geolocation) {                
            storeGeolocation({ type: 'not_supported' });
        }
    }, []);

    function allowLocation() {        
        navigator.geolocation.getCurrentPosition(localtionFulfilled, locationRejected);
    }

    function localtionFulfilled(location) {        
        storeGeolocation({ 
            type: 'available', 
            longitude: location.coords.longitude, 
            latitude: location.coords.latitude 
        });
    }

    function locationRejected(error) {        
        storeGeolocation({ type: 'not_granted' });
    }

    function storeGeolocation(geolocation) {        
        logEvent(analytics, 'enable_location');
        mutateProfile({ geolocation });
    }    

    return (
        <TopLayout gap="2" py="5">                  
            <Flex direction="column" pt="4" align="center" style={{ height: '100%' }}>                
                <WizardProgressBar previousRoute="/basic-info/3" percentage={95} />                
                <Flex direction="column" justify="center" align="center" height="100%" gap="5" mb="3">
                    <Heading size="6" align="center">{ t('wizard_geolocation') }</Heading>                                        
                    <Text align="center" style={{ width: '80%' }} mb="6">{ t('subtitle_private') }</Text>
                    <img src={Location} />                    
                </Flex>
            </Flex>                        
            <Button primary onClick={allowLocation}>{ t(t(`action_enable_location`)) }</Button>            
            <Button plain to="/basic-info-complete">{ t(t(`action_skip`)) }</Button>       
        </TopLayout>
    );
}
