import { Card, Flex, Link, Text } from "@radix-ui/themes";
import { useSelector } from "react-redux";
import { GoCloudOffline } from 'react-icons/go';
import './connection-error.scss';
import store from "@/store";
import { reconnect, retry } from "@/api/connectionSlice";
import { useTranslation } from "react-i18next";

export default function ConnectionError({ }) {
  const { t } = useTranslation();

  const noConnection = useSelector(state => state.connection.noConnection);    
  const retrying = useSelector(state => state.connection.retrying);

  function attemptRetry() {
    if (noConnection) {
      store.dispatch(reconnect());
      store.dispatch(retry());
    }    
  }

  return noConnection ? (
    <Card className="connection-error">
      <Flex justify="between" align="center">
        <Flex mr="6">
          <GoCloudOffline size="24" />
          <Text ml="4">{ t('text_offline') }</Text>
        </Flex>
        {retrying ? <Text className="retrying">{ t('action_retrying') }</Text> : <Link onClick={attemptRetry}>{ t('action_retry') }</Link>}
      </Flex>
    </Card>
  ) : undefined;
}