import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { analytics } from '@/firebase-globals';
import { logEvent } from "firebase/analytics";


export default function useWizard(name, getDataHook, patchDataHook, steps, fastForwardCompleteUrl) {    
    const [searchParams] = useSearchParams();

    function getNextRoute(stepIndex) {    
        const shouldFastForward = searchParams.get('ff') && fastForwardCompleteUrl;
        const completeUrl = shouldFastForward ? fastForwardCompleteUrl :  "/" + name + "-complete";
        return (stepIndex < steps.length - 1) ? "/" + name + "/" + (stepIndex + 1) : completeUrl;
    }

    const navigate = useNavigate();
    const routeParams = useParams();        
    const index = routeParams.index;

    const inferIndex = index == null;

    const { isFetching, data } = getDataHook();    

    useEffect(() => {
        if (data != null && inferIndex) {
            const stepIndex = inferStepIndex(data, steps);                
            navigate(getNextRoute(stepIndex - 1));                   
        }
    }, [data, inferIndex])    
        
    const stepIndex = Number.parseInt(index || "0"); // TODO: Error handling    
    const step = steps[stepIndex];

    const nextRoute = getNextRoute(stepIndex);
    const previousRoute = (stepIndex > 0) ? "/" + name + "/" + (stepIndex - 1) : null;
    const percentage = (stepIndex + 1) / (steps.length + 1) * 100;

    const [value, setValue] = useState('');
    useEffect(() => {        
        if (data != null) {            
            const newValue = data[step.name];            
            setValue(newValue == null ? '' : newValue);            
        }
    }, [data, step.name]);

    const [error, setError] = useState('');
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setError('');
        try {            
            setIsValid(step.validator(value));
        } catch (e) {
            setIsValid(false);
            setError(e.message);
        }        
    }, [step, value]);
    
    const [mutateProfile, updateResult] = patchDataHook();

    function nextStep() {
        logEvent(analytics, `continue_${step.name}`);
        mutateProfile({ [step.name]: value });
    }

    useEffect(() => {
        if (updateResult.isSuccess) {
            navigate(nextRoute);
        }
        if (updateResult.isError) {
            console.log(updateResult.error);            
            // TODO: Better error handling / maybe some toast?
        }
    }, [updateResult]);

    return { isLoading: isFetching, isValid, error, value, setValue, step, percentage, nextStep, nextRoute, previousRoute };
}


function inferStepIndex(data, steps) {
    let currentStep = 0;    
    for (const step of steps) {
        if (step.name in data) {
            currentStep++;
        } else {
            return currentStep;
        }
    }
    return currentStep;
}
