import './text-area.scss';

export default function TextArea({ placeholder, ...rest }) {    
    const { error, valueChange, forwardedRef, ...props } = rest;

    function onChange(event) {        
        if (valueChange) {
            valueChange(event.target.value);
        }
    }    

    return (         
        <div className="text-area-grow-wrap">
            <textarea placeholder={placeholder} onChange={onChange} {...props} ref={forwardedRef} rows={1}>{rest.value}</textarea>
            <div className="text-area-grow-inner">{rest.value}</div>
        </div>              
    );
}
