import { Container } from "@radix-ui/themes";

export default function PrivacyPolicyPage() {
    return (
        <Container mx="7" py="5" pb="7">
            <h1>Privacy Policy</h1>
            <p>Effective date: 1st March 2024</p>
            <h2>1. Information Collection and Use</h2>
            <p>We collect various types of information for various purposes to provide and improve our service to you, including:</p>
            <ul>
                <li><b>Personal Data:</b> Email, first name, birthday.</li>
                <li><b>Usage Data:</b> We may also collect information on how the Service is accessed and used.</li>
            </ul>
            <h2>2. Use of Data</h2>
            <p>Friendify uses the collected data for various purposes:</p>
            <ul>
                <li>To provide and maintain our Service</li>
                <li>To notify you about changes to our Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                <li>To provide customer support</li>
                <li>To gather analysis or valuable information so that we can improve our Service</li>
                <li>To monitor the usage of our Service</li>
                <li>To detect, prevent, and address technical issues</li>
                <li>To use data for ad targeting purposes to provide you with relevant advertisements</li>
            </ul>
            <h2>3. Cookies</h2>      
            <p>We use two types of cookies:</p>      
            <ul>
                <li>Neccessary cookies. These are essential for proper functioning of our Service. Unlike other cookies, we use these cookies without your consent.</li>
                <li>Analytical cookies. These cookies allow us to measure traffic and usage of our Service in order to improve the quality. We use these only when you allow us, we ask for consent via the bottom popup banner.</li>
            </ul>
            <p>Our trusted third-party service providers may set cookies aswell. Links to privacy policy of those third party services used by our Service:</p>
            <ul>
                <li><a href="https://policies.google.com/privacy" target="_blank">Google Analytics</a></li>
                <li><a href="https://sentry.io/privacy/">Sentry</a></li>
            </ul>
            <p></p>
            <h2>4. Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)</h2>
            <p>If you are from the European Economic Area (EEA), Friendify legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
            <h2>5. Your Data Protection Rights Under GDPR</h2>
            <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights aimed at allowing you to control your personal information. You can contact us at <a href="mailto:info@friendify.io">info@friendify.io</a> to exercise these rights.</p>
            <h2>6. Data Sharing and Disclosure</h2>
            <p>Friendify may share your data with third parties in the following situations:</p>
            <ul>
            <li><b>Service Providers:</b> We may share your data with third-party companies and individuals that we employ to facilitate our Service, provide the Service on our behalf, perform service-related services, or assist us in analyzing how our Service is used.</li>
            <li><b>Legal Requirements:</b> Friendify may disclose your Personal Data in the good faith belief that such action is necessary to:
            <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of Friendify</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of users of the Service or the public</li>
            <li>Protect against legal liability</li>
            </ul>
            </li>
            </ul>            
            <h2>7. Data Retention</h2>
            <p>Friendify will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy. We will notify you.</p>
            <h2>9. Governing Law</h2>
            <p>These Terms and Conditions shall be governed and construed in accordance with the laws of Slovakia, without regard to their conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</p>
            <h2>10. Dispute Resolution</h2>
            <p>In the event of a dispute, users are encouraged to contact Friendify directly to seek a resolution. If the dispute cannot be resolved through direct communication, users agree to submit to the exclusive jurisdiction of the courts located in Slovakia or the Czech Republic for the resolution of any disputes.</p>
            <h2>11. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at <a href="mailto:info@friendify.io">info@friendify.io</a>.</p>
        </Container>
    )
}