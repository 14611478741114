import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './base';

export const followupApi = createApi({
    reducerPath: 'followupApi',
    baseQuery: customFetchBase, 
    tagTypes: ['Followups'],
    endpoints: (builder) => ({
        getFollowups: builder.query({
            query: () => ({
                url: '/followups',
                method: 'GET',             
            }),
            providesTags: ['Followups'],
        }),
    }),
})

export const {
    useGetFollowupsQuery,    
    endpoints
} = followupApi;
