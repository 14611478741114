import { useTranslation } from 'react-i18next';
import './choice.scss';
import './multiple-choice.scss';
import CheckIcon from "@/assets/icons/check.svg";
import * as Checkbox from '@radix-ui/react-checkbox';

export default function MultipleChoice({ choices, value, valueChange, ...props }) {    
    const { t } = useTranslation();    
    
    function onCheckedChange(choice, checked) {
        if (checked && value.indexOf(choice) < 0) {
            valueChange([...value, choice]);
        }
        if (!checked && value.indexOf(choice) >= 0) {
            valueChange(value.filter(v => v !== choice));
        }        
    }

    return (
        <div className="ChoiceGroupRoot">            
            { choices.map((choice, index) => (                
                <Checkbox.Root className="ChoiceGroupItem" key={choice} 
                    id={'choice_' + choice}
                    checked={value.indexOf(choice) >= 0}
                    onCheckedChange={(checked) => onCheckedChange(choice, checked)}>                        
                    <label className="Label" htmlFor={'choice_' + choice}>{ props.translations ? props.translations[index] : t('choice_' + choice) }</label>
                    <div className="ChoiceIndicatorOutline">
                        <Checkbox.Indicator className="MultipleChoiceIndicator">
                            <img src={CheckIcon} width="24" height="24" />                            
                        </Checkbox.Indicator>
                    </div>
                </Checkbox.Root>                                           
            )) }                        
        </div>        
    );
}
