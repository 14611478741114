import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase, { setAccessToken } from './base';
import { login, logout } from './authSlice';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: customFetchBase,
    tagTypes: [],

    endpoints: (builder) => ({
        signInByEmail: builder.mutation({
            query: (email) => ({
                url: '/users/sign-in/email',
                method: 'POST',
                body: { email },
                public: true,
            }),
        }),

        verifyEmail: accessTokenReturningMutation(builder, '/users/sign-in/email/verify'),                    
        signInByFacebook: accessTokenReturningMutation(builder, '/users/sign-in/facebook'),
        signInByGoogle: accessTokenReturningMutation(builder, '/users/sign-in/google'),

        logout: builder.mutation({
            query: () => ({
                url: '/users/logout',
                method: 'POST',      
                credentials: 'include',
                public: true,          
            }),
            onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;                    
                    dispatch(logout());
                    // Unfortunately we cannot remove the access token here
                } catch (error) {
                    console.log("Error logging out: ", error);
                }
            },
        })
    }),
})

function accessTokenReturningMutation(builder, url) {
    return builder.mutation({
        query: (code) => ({
            url,
            method: 'POST',
            body: { code },
            credentials: 'include',
            public: true,
        }),
        onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
            try {
                const { data } = await queryFulfilled;                    
                setAccessToken(data.access_token);
                dispatch(login());
            } catch (error) {
                console.log("Error signing in: ", error);
                setAccessToken(null);
            }
        },
    });
}

export const {
    useSignInByEmailMutation,
    useVerifyEmailMutation,
    useSignInByFacebookMutation,
    useSignInByGoogleMutation,
    useLogoutMutation,
    endpoints
} = authApi;
