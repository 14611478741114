import { TextField as UITextField } from "@radix-ui/themes";

export default function TextField({ placeholder, ...rest }) {    
    const color = rest.error ? 'red' : undefined;    
    const style = rest.error ? { color: 'var(--red-11)' } : undefined;
    const { error, valueChange, forwardedRef, ...props } = rest;

    function onChange(event) {
        if (valueChange) {
            valueChange(event.target.value);
        }
    }    

    return (
        <UITextField.Root style={{ width: '100%' }}>
            <UITextField.Input radius="full" color={color} size="3" style={{ height: '56px', paddingLeft: '24px', paddingRight: '24px', ...style }} placeholder={placeholder} onChange={onChange} {...props} ref={forwardedRef} />                        
        </UITextField.Root>
    );
}