import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {      
      en: {
        translation: {
          // Welcome
          welcome_title: 'Find Your Perfect Friends & Skip The Awkward Intros',
          welcome_subtitle: 'Our AI finds and connects you with future friends, making every meeting feel like catching up with an old pal.',                              
          no_account_question: 'Don’t have an account?',          
                    
          // Sign In
          sign_in_title: "Let’s start with your email",
          placeholder_email: 'Enter your email',                    
          action_login_facebook: 'Continue with Facebook',
          action_login_google: 'Continue with Google',

          // Email Verification
          verify_email_title: 'Check your email',
          verify_email_subtitle: 'We have sent you an email with a verification link. Click on the link to proceed.',
          no_email_question: 'Haven’t received the email?',
          action_resent: 'Resend',

          verifying_email_title: 'Verifying your sign in',
          verifying_email_subtitle: 'Please wait while we verify your sign in attempt.',
          verifying_email_error_title: 'Unable to verify your identity',
          
          error_verification_code_404: 'Invalid or expired verification code. ',          

          // Registration
          wizard_first_name: 'Your First Name',
          wizard_birthday: 'Your Birthday',
          wizard_gender: 'What Is Your Gender?',
          wizard_languages: 'Which Languages Do You Speak?',

          subtitle_public: 'This will be shown on your profile',
          subtitle_private: 'We will keep this information private',
          subtitle_age: 'People will see only your age',
          subtitle_languages: 'We will only match you with people who speak one of your languages',

          placeholder_first_name: 'Enter your first name',          

          choice_male: 'Male',
          choice_female: 'Female',
          choice_non_binary: 'Non-Binary',

          choice_en: 'English',
          choice_sk: 'Slovak',
          choice_cs: 'Czech',

          success_registration_title: 'Registration Complete',
          success_registration_subtitle: 'To find you a friend, we’ll first:',
          success_registration_next_step_1: 'Gather basic info',
          success_registration_next_step_2: 'Learn about your likes and dislikes',
          success_registration_next_step_3: 'Learn what you find funny',
          success_registration_explanation: 'This will help us match you with the perfect friend',

          // Basic info
          wizard_career: 'Your Career Status',
          wizard_family: 'Your Family Status',
          wizard_kids: 'Your Kids',
          wizard_education: 'Your Education',
          wizard_geolocation: 'Let us access your location to find people near you',

          choice_student: 'Student',
          choice_young_professional: 'Young Professional',
          choice_senior_professional: 'Senior Professional',
          choice_business_owner: 'Business Owner',
          choice_unemployed: 'Unemployed',
          choice_stay_at_home: 'Stay-at-home',
          choice_retired: 'Retired',

          choice_single: 'Single',
          choice_married: 'Married',
          choice_in_a_relationship: 'In a relationship',
          choice_divorced: 'Divorced',
          choice_widowed: 'Widow',

          choice_elementary_school: 'Elementary School',
          choice_high_school: 'High School',
          choice_university: 'University',

          placeholder_kids: 'How many kids do you have?',

          success_basic_info_title: 'Now let the fun begin',
          success_basic_info_subtitle: 'We will show you some characters with different lifestyles. Swipe right if you think you fit the lifestyle. Swipe left if you don’t.',
          success_basic_info_action: 'Start swiping',

          // Lifestyle
          success_lifestyle_title: 'We have some follow-up questions',
          success_lifestyle_subtitle: 'Let’s clarify some things. This will enable us to make the perfect match.', 
          success_lifestyle_action: 'Let’s clarify',

          // Follow up
          success_follow_up_title: 'Now it’s time for the jokes',
          success_follow_up_subtitle: 'We will show you some jokes.  Swipe right if you find the content  funny. Swipe left if you don’t.', 
          success_follow_up_action: 'Let’s have fun',

          wizard_love: 'What do you really love?',          
          wizard_hate: 'What do you truly hate?',

          placeholder_specific: 'The more weird thing the better',

          // Jokes
          success_jokes_granted_title: 'Congratulations!',
          success_jokes_denied_title: 'Congratulations!',
          success_jokes_granted_subtitle: 'We have everything we need to match you with your perfect friends! We will send you a notification once we find a perfect match!',          
          success_jokes_denied_subtitle: 'We have everything we need to match you with your perfect friends! To receive notifications please allow them in the settings first!',          

          // Notification
          success_notification_title: 'Get notified once matched!',
          success_notification_subtitle: 'We have everything we need to match you with your perfect friends!',
          success_notification_subtitle_2: 'We will send you a notification once we find your perfect match!',
          success_notification_action: 'Turn on notifications',          

          // Common
          action_sign_in: 'Sign In',
          action_sign_up: 'Sign Up',
          action_sign_in_again: 'Sign in again',
          action_continue: 'Continue',   
          action_get_started: 'Get started',
          action_yes: 'Yes',
          action_no: 'No',
          action_joke_yes: 'Funny',
          action_joke_no: 'Lame',
          action_send: 'Send',
          action_try_again: 'Try again',
          action_retry: 'Retry',
          action_retrying: 'Retrying',
          action_enable_location: 'Enable location',
          action_skip: 'Skip',

          error_page_title: 'Oops!',
          error_invalid_email: 'Invalid email address',
          error_underage: 'Sorry, you must be at least 18 years old to continue.',
          error_not_found: 'The requested content doesn’t exist.',
          error_too_many_attempts: 'Too many attempts. Please try again later.',
          error_unexpected: 'Unexpected error, please try again later',
          error_server: 'Unable to connect to server',
          error_unable_to_login_facebook: 'Unable to login with Facebook',
          error_unable_to_login_google: 'Unable to login with Google',

          or: 'Or',

          help_first_message: 'Write a first message',
          date_today: 'Today',

          text_you_have: 'You have',
          text_new_message_1: 'new message',
          text_new_messages_2_4: 'new messages',
          text_new_messages_5_or_more: 'new messages',

          text_check_connection: 'Please check your internet connection',
          text_offline: 'You are currently offline',

          title_matches: 'Matches',
          title_unable_to_load: 'Unable to load the page',          

          gender_short_male: 'M',
          gender_short_female: 'F',
          gender_short_non_binary: '',

          placeholder_type_message: 'Type message',
          placeholder_search: 'Search',

          emoji_category_suggested: 'Suggested',
          emoji_category_smileys_people: 'Smileys / People',
          emoji_category_animals_nature: 'Animals / Nature',
          emoji_category_food_drink: 'Food / Drink',
          emoji_category_travel_places: 'Travel / Places',
          emoji_category_activities: 'Activities',
          emoji_category_objects: 'Objects',
          emoji_category_symbols: 'Symbols',
          emoji_category_flags: 'Flags',         
          
          cookies_text: 'We use cookies to run our website and to ensure you get the best possible user experience. To learn more how we use them see our',
          cookies_privacy_policy: 'Privacy policy',
          button_accept_all: 'Accept all',
          button_deny: 'Deny',

          agreement_part1: 'By clicking "Continue" you agree with our',
          agreement_part2: 'terms of service',
          agreement_part3: 'and with our',
          agreement_part4: 'privacy policy',          

          action_logout: 'Logout',

          text_new_match: 'New',
        },
      },      
      sk: {
        translation: {
          // Welcome
          welcome_title: 'Nájdi si nových priateľov a preskoč trápne úvody',
          welcome_subtitle: 'Naša umelá inteligencia ti pomôže nájsť tvojich budúcich priateľov, s ktorými si budeš instantne rozumieť.',                              
          no_account_question: 'Nemáš ešte účet?',        

          // Sign In
          sign_in_title: "Začnime s tvojim emailom",
          placeholder_email: 'Tvoja emailová adresa',                    
          action_login_facebook: 'Prihlás sa cez Facebook',
          action_login_google: 'Prihlás sa cez Google',

          // Email Verification
          verify_email_title: 'Skontroluj si svoju emailovú schránku',
          verify_email_subtitle: 'Na tvoj email sme ti odoslali verifikačný mail s odkazom. Klikni naň, aby si mohol pokračovať.',
          no_email_question: 'Neprišiel ti žiaden email?',
          action_resent: 'Odoslať znova',

          verifying_email_title: 'Overujeme tvoje prihlásenie',
          verifying_email_subtitle: 'Prosím počkaj, kým si overíme tvoj pokus o prihlásenie.',
          verifying_email_error_title: 'Nepodarilo sa overiť tvoju identitu',
          
          error_verification_code_404: 'Nesprávny alebo expirovaný verifikačný kód.',          

          // Registration
          wizard_first_name: 'Tvoje krstné meno',
          wizard_birthday: 'Tvoje narodeniny',
          wizard_gender: 'Aké je tvoje pohlavie?',
          wizard_languages: 'Ktorými jazykmi rozprávaš?',

          subtitle_public: 'Toto bude zobrazené v tvojom profile',
          subtitle_private: 'Túto informáciu si necháme pre seba',
          subtitle_age: 'Ľudia budú vidieť iba tvoj aktuálny vek',
          subtitle_languages: 'Spojíme ťa iba s ľuďmi, ktorí rozprávajú aspoň s jedným z vybraných jazykov',

          placeholder_first_name: 'Zadaj svoje krstné meno',          

          choice_male: 'Muž',
          choice_female: 'Žena',
          choice_non_binary: 'Iné',

          choice_en: 'Anglicky',
          choice_sk: 'Slovensky',
          choice_cs: 'Česky',       

          success_registration_title: 'Registrácia dokončená',
          success_registration_subtitle: 'Aby sme ti vedeli nájsť tvojho prvého kamaráta, budeme od teba ešte potrebovať:',
          success_registration_next_step_1: 'Základné informácie',
          success_registration_next_step_2: 'Naučiť sa čo máš a nemáš rád',
          success_registration_next_step_3: 'Naučiť sa čo ti príde vtipné',
          success_registration_explanation: 'Toto nám pomôže spojiť ťa s perfektným kamarátom',

          // Basic info
          wizard_career: 'Tvoja práca',
          wizard_family: 'Tvoj rodinný stav',
          wizard_kids: 'Tvoje deti',
          wizard_education: 'Tvoje vzdelanie',          
          wizard_geolocation: 'Tvoja poloha nám umožní nájsť ľudí v tvojom okolí',

          choice_student: 'Študent',
          choice_young_professional: 'Začínajúci profesionál',
          choice_senior_professional: 'Skúsený profesionál',
          choice_business_owner: 'Podnikateľ',
          choice_unemployed: 'Nezamestnaný',
          choice_stay_at_home: 'Starajúci sa o domácnosť',
          choice_retired: 'Dôchodca',

          choice_single: 'Slobodný',
          choice_married: 'Ženatý / Vydatá',
          choice_in_a_relationship: 'Vo vzťahu',
          choice_divorced: 'Rozvedený',
          choice_widowed: 'Vdovec',

          choice_elementary_school: 'Základná škola',
          choice_high_school: 'Stredná škola',
          choice_university: 'Vysoká škola',

          placeholder_kids: 'Koľko máš detí?',

          success_basic_info_title: 'Teraz príde tá zábavná časť',
          success_basic_info_subtitle: 'Ukážeme ti niekoľko rôznych životných štýlov. Potiahni prstom doprava ak si myslíš, že ťa daný životný štýl vystihuje. Potiahni prstom doľava ak to tak nie je.',
          success_basic_info_action: 'Poďme na to',

          // Lifestyle
          success_lifestyle_title: 'Máme pre teba zopár doplňujúcich otázok',
          success_lifestyle_subtitle: 'Ujasníme si niektoré veci ohľadom tvojho životného štýlu. Umožní nám to ťa presnejšie matchnúť.', 
          success_lifestyle_action: 'Poďme na to',

          // Follow up
          success_follow_up_title: 'Teraz je čas na vtipy',
          success_follow_up_subtitle: 'Ukážeme ti niekoľko vtipov. Ak sa ti zdá obsah vtipný, potiahni prstom doprava. Ak nie, potiahni prstom doľava.', 
          success_follow_up_action: 'Poďme sa smiať',

          wizard_love: 'Čo máš fakt rád?',          
          wizard_hate: 'Čo z duše neznášaš?',

          placeholder_specific: 'Čím divnejšie tým lepšie',

          // Jokes
          success_jokes_granted_title: 'Gratulujeme!',
          success_jokes_denied_title: 'Gratulujeme!',
          success_jokes_granted_subtitle: 'Máme všetko, čo potrebujeme, aby sme ťa spojili s tvojimi dokonalými priateľmi! Keď nájdeme perfektnú zhodu, pošleme ti notifikáciu!',          
          success_jokes_denied_subtitle: 'Máme všetko, čo potrebujeme, aby sme ťa spojili s tvojimi dokonalými priateľmi! Aby sme ťa mohli upozorniť, keď nájdeme zhodu, musíš povoliť notifikácie!',          

          // Notification          
          success_notification_title: 'Notifikujeme ťa akonáhle nájdeme zhodu!',
          success_notification_subtitle: 'Máme všetko, čo potrebujeme, aby sme ťa spojili s tvojimi perfektnými priateľmi!',
          success_notification_subtitle_2: 'Pošleme ti notifikáciu, keď nájdeme zhodu!',
          success_notification_action: 'Zapnúť notifikácie',
          
          // Common
          action_sign_in: 'Prihlásiť sa',
          action_sign_up: 'Zaregistruj sa!',
          action_sign_in_again: 'Prihlásiť sa znovu',
          action_continue: 'Pokračovať',  
          action_get_started: 'Poďme na to',
          action_yes: 'Áno',
          action_no: 'Nie',
          action_joke_yes: 'Vtipné',
          action_joke_no: 'Trápne',
          action_send: 'Pošli',
          action_try_again: 'Skúsiť znovu',
          action_retry: 'Skúsiť znovu',
          action_retrying: 'Skúšame znovu',
          action_enable_location: 'Povoliť prístup k polohe',
          action_skip: 'Preskočiť',

          error_page_title: 'Hups!',
          error_invalid_email: 'Nesprávna emailová adresa',
          error_underage: 'Lutujeme, ale musíš mať aspoň 18 rokov na to, aby si sa mohol pokračovať.',
          error_not_found: 'Hľadaný obsah sa nepodarilo nájsť.',
          error_too_many_attempts: 'Príliš veľa pokusov. Vyskúšaj to neskôr znova.',
          error_unexpected: 'Neočakávaná chyba, vyskúšaj to neskôr znova',
          error_server: 'Nepodarilo sa pripojiť na server',
          error_unable_to_login_facebook: 'Nepodarilo sa prihlásiť cez Facebook',
          error_unable_to_login_google: 'Nepodarilo sa prihlásiť cez Google',

          or: 'Alebo',
        
          help_first_message: 'Napíš prvú správu',
          date_today: 'Dnes',

          text_you_have: 'Máš',
          text_new_message_1: 'novú správu',
          text_new_messages_2_4: 'nové správy',
          text_new_messages_5_or_more: 'nových správ',          

          text_check_connection: 'Prosím skontroluj svoje internetové pripojenie',
          text_offline: 'Momentálne si offline',

          title_matches: 'Zhody',
          title_unable_to_load: 'Nepodarilo sa načítať stránku',          

          gender_short_male: 'M',
          gender_short_female: 'Ž',
          gender_short_non_binary: '',

          placeholder_type_message: 'Napíš správu',
          placeholder_search: 'Hladať',

          emoji_category_suggested: 'Odporúčané',
          emoji_category_smileys_people: 'Úsmevy / ľudia',
          emoji_category_animals_nature: 'Zvieratá / príroda',
          emoji_category_food_drink: 'Jedlo / nápoje',
          emoji_category_travel_places: 'Cestovanie / miesta',
          emoji_category_activities: 'Aktivity',
          emoji_category_objects: 'Objekty',
          emoji_category_symbols: 'Symboly',
          emoji_category_flags: 'Vlajky',

          cookies_text: 'Na prevádzku nášho webu a na zabezpečenie čo najlepšieho používateľského zážitku používame súbory cookies. Ak sa chceš dozvedieť viac pozri si naše ',
          cookies_privacy_policy: 'Zásady ochrany osobných údajov',
          button_accept_all: 'Prijať všetko',
          button_deny: 'Odmietnuť',

          agreement_part1: 'Kliknutím na tlačidlá "Pokračovať" alebo "Prihlásiť sa" súhlasíš s našimi',
          agreement_part2: 'podmienkami používania',
          agreement_part3: 'a s našimi',
          agreement_part4: 'zásadami ochrany osobných údajov',

          action_logout: 'Odhlásiť sa',

          text_new_match: 'Nová',
        }      
      },        
      cs: {
        translation: {
          // Welcome
          welcome_title: 'Najdi si nové přátele a přeskoč trapné začátky',
          welcome_subtitle: 'Naše umělá inteligence ti pomůže najít tvé budoucí přátele, se kterými si budeš instantně rozumět.',                              
          no_account_question: 'Nemáš ještě účet?',        

          // Sign In
          sign_in_title: "Začněme s tvým emailem",
          placeholder_email: 'Tvoje emailová adresa',                    
          action_login_facebook: 'Přihlas se přes Facebook',
          action_login_google: 'Přihlas se přes Google',

          // Email Verification
          verify_email_title: 'Zkontroluj si svoji emailovou schránku',
          verify_email_subtitle: 'Na tvůj email jsme ti odeslali verifikační mail s odkazem. Klikni na něj, abys mohl pokračovat.',
          no_email_question: 'Nepřišel ti žádný email?',
          action_resent: 'Odeslat znovu',

          verifying_email_title: 'Ověřujeme tvé přihlášení',
          verifying_email_subtitle: 'Prosím počkej, než si ověříme tvůj pokus o přihlášení.',
          verifying_email_error_title: 'Nepodařilo se ověřit tvou identitu',
          
          error_verification_code_404: 'Nesprávný nebo expirovaný verifikační kód.',          

          // Registration
          wizard_first_name: 'Tvé křestní jméno',
          wizard_birthday: 'Tvé narozeniny',
          wizard_gender: 'Jaké je tvé pohlaví?',
          wizard_languages: 'Kterými jazyky mluvíš?',

          subtitle_public: 'Toto bude zobrazeno ve tvém profilu',
          subtitle_private: 'Tuto informaci si necháme pro sebe',
          subtitle_age: 'Lidé budou vidět pouze tvůj aktuální věk',
          subtitle_languages: 'Spojíme tě pouze s lidmi, kteří mluví alespoň s jedním z vybraných jazyků',

          placeholder_first_name: 'Zadej své křestní jméno',          

          choice_male: 'Muž',
          choice_female: 'Žena',
          choice_non_binary: 'Jiné',

          choice_en: 'Anglicky',
          choice_sk: 'Slovensky',
          choice_cs: 'Česky',       

          success_registration_title: 'Registrace dokončena',
          success_registration_subtitle: 'Byl jsi úspěšně zaregistrován.',
          success_registration_next_steps: 'Abychom ti uměli najít tvého prvního kamaráda, budeme od tebe ještě potřebovat:',
          success_registration_next_step_1: 'Základní informace',
          success_registration_next_step_2: 'Naučit se co máš a nemáš rád',
          success_registration_next_step_3: 'Naučit se co ti přijde vtipné',
          success_registration_explanation: 'Tohle nám pomůže spojit tě s perfektním kamarádem',

          // Basic info
          wizard_career: 'Tvoje práce',
          wizard_family: 'Tvůj rodinný stav',
          wizard_kids: 'Tvé děti',
          wizard_education: 'Tvé vzdělání',         
          wizard_geolocation: 'Tvoja poloha nám umožní nájsť ľudí v tvojom okolí', 

          choice_student: 'Student',
          choice_young_professional: 'Začínající profesionál',
          choice_senior_professional: 'Zkušený profesionál',
          choice_business_owner: 'Podnikatel',
          choice_unemployed: 'Nezaměstnanýý',
          choice_stay_at_home: 'Starající se o domácnost',
          choice_retired: 'Důchodce',

          choice_single: 'Svobodný',
          choice_married: 'Ženatý / Vdaná',
          choice_in_a_relationship: 'Ve vztahu',
          choice_divorced: 'Rozvedený',
          choice_widowed: 'Vdovec',

          choice_elementary_school: 'Základní škola',
          choice_high_school: 'Střední škola',
          choice_university: 'Vysoká škola',

          placeholder_kids: 'Kolik máš dětí?',

          success_basic_info_title: 'Nyní přijde ta zábavná čás',
          success_basic_info_subtitle: 'Ukážeme ti několik různých životních stylů. Potáhni prstem doprava pokud si myslíš, že tě daný životní styl vystihuje. Potáhni prstem doleva, pokud tomu tak není.',
          success_basic_info_action: 'Pojďme na to',

          // Lifestyle
          success_lifestyle_title: 'Máme pro tebe pár doplňujících otázek',
          success_lifestyle_subtitle: 'Podměj si ujasnit některé věci ohledně tvého životního stylu. Umožní nám to tě přesněji matchnout.', 
          success_lifestyle_action: 'Pojďme na to',

          // Follow up
          success_follow_up_title: 'Nyní je čas na vtipy',
          success_follow_up_subtitle: 'Ukážeme ti několik vtipů. Pokud se ti zdá obsah vtipný, potáhni prstem doprava. Pokud ne, potáhni prstem doleva.', 
          success_follow_up_action: 'Pojďme se smát',

          wizard_love: 'Co máš opravdu rád?',          
          wizard_hate: 'Co z duše nesnášíš?',          

          placeholder_specific: 'Čím divněji tím lépe',

          // Jokes
          success_jokes_granted_title: 'Gratulujeme!',
          success_jokes_denied_title: 'Gratulujeme!',
          success_jokes_granted_subtitle: 'Máme všechno, co potřebujeme, abychom tě spojili s tvými dokonalými přáteli! Když najdeme perfektní shodu, pošleme ti notifikaci!',          
          success_jokes_denied_subtitle: 'Máme všechno, co potřebujeme, abychom tě spojili s tvými dokonalými přáteli! Abychom tě mohli upozornit, když najdeme shodu, musíš povolit notifikace!',          
          
          // Notification          
          success_notification_title: 'Notifikujeme tě až najdeme shodu!',
          success_notification_subtitle: 'Máme vše, co potřebujeme, abychom tě spojili s tvými perfektními přáteli!',
          success_notification_subtitle_2: 'Pošleme ti notifikaci, až najdeme shodu!',
          success_notification_action: 'Zapnout notifikace',
          
          // Common
          action_sign_in: 'Přihlásit se',
          action_sign_up: 'Zaregistruj se!',
          action_sign_in_again: 'Přihlásit se znovu',
          action_continue: 'Pokračovat',  
          action_get_started: 'Pojďme na to',
          action_yes: 'Ano',
          action_no: 'Ne',
          action_joke_yes: 'Legrační',
          action_joke_no: 'Trapné',
          action_send: 'Pošli',
          action_try_again: 'Zkusit znovu',
          action_retry: 'Zkusit znovu',
          action_retrying: 'Zkoušíme znovu',
          action_enable_location: 'Povolit přístup k poloze',
          action_skip: 'Přeskočit',

          error_page_title: 'Hups!',
          error_invalid_email: 'Nesprávná emailová adresa',
          error_underage: 'Litujeme, ale musíš mít alespoň 18 let na to, aby ses mohl pokračovat.',
          error_not_found: 'Hledaný obsah se nepodařilo najít.',
          error_too_many_attempts: 'Příliš mnoho pokusů. Vyzkoušej to později znovu.',
          error_unexpected: 'Neočekávaná chyba, vyzkoušej to později znovu',
          error_server: 'Nepodařilo se připojit na server',
          error_unable_to_login_facebook: 'Nepodařilo se přihlásit přes Facebook',
          error_unable_to_login_google: 'Nepodařilo se přihlásit přes Google',  

          or: 'Nebo',

          help_first_message: 'Napiš první zprávu',
          date_today: 'Dnes',

          text_you_have: 'Máš',
          text_new_message_1: 'novou zprávu',
          text_new_messages_2_4: 'nové zprávy',
          text_new_messages_5_or_more: 'nových zpráv',          

          text_check_connection: 'Prosím zkontroluj své internetové připojení',
          text_offline: 'Momentálně si offline',

          title_matches: 'Shody',
          title_unable_to_load: 'Nepodařilo se načíst stránku',          

          gender_short_male: 'M',
          gender_short_female: 'Ž',
          gender_short_non_binary: '',

          placeholder_type_message: 'Napiš zprávu',
          placeholder_search: 'Hledat',

          emoji_category_suggested: 'Doporučeno',
          emoji_category_smileys_people: 'Úsměvy / lidé',
          emoji_category_animals_nature: 'Zvířata / příroda',
          emoji_category_food_drink: 'Jídlo / nápoje',
          emoji_category_travel_places: 'Cestování / místa',
          emoji_category_activities: 'Aktivity',
          emoji_category_objects: 'Objekty',
          emoji_category_symbols: 'Symboly',
          emoji_category_flags: 'Vlajky',   

          cookies_text: 'K provozování našich webových stránek a k zajištění co nejlepšího uživatelského zážitku používáme soubory cookies. Chceš-li se dozvědět více, podívej se na naše',
          cookies_privacy_policy: 'Zásady ochrany osobních údajů',
          button_accept_all: 'Prijať vše',
          button_deny: 'Odmítnout',
          
          agreement_part1: 'Kliknutím na tlačidlá "Pokračovat" nebo "Přihlas" souhlasíš s našimi',
          agreement_part2: 'podmínkami používání',
          agreement_part3: 'a s našimi',
          agreement_part4: 'zásadami ochrany osobných údajů',

          action_logout: 'Odhlásit se',

          text_new_match: 'Nová',
        }        
      }            
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
