import { Heading, Text } from "@radix-ui/themes";
import FacebookIcon from "@/assets/icons/facebook.svg";
import GoogleIcon from "@/assets/icons/google.svg";
import Logo from "@/components/logo";
import { Button, SocialButton, TextDivider, TextField } from "@/components";
import { TopLayout } from "@/layouts";
import { useEffect, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSignInByEmailMutation } from "@/api/authApi";
import TermsOfServicePage from "./terms-of-service-page";

const facebookAppId = '1110176460340410';
const facebookStateParam = '0';
const facebookRedirectUri = `${import.meta.env.VITE_FRONTEND_BASE_URL}/sign-in/facebook`;
const facebookSignIn = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${facebookAppId}&redirect_uri=${facebookRedirectUri}&state=${facebookStateParam}`;

const googleClientId = '912240153655-6a8epklv9a9qf7d3tjlasn970pkfeb88.apps.googleusercontent.com';
const googleStateParam = '0';
const googleRedirectUri = `${import.meta.env.VITE_FRONTEND_BASE_URL}/sign-in/google`;
const googleSignIn = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${googleRedirectUri}&client_id=${googleClientId}&response_type=code&state=${googleStateParam}&scope=https%3A//www.googleapis.com/auth/userinfo.email&include_granted_scopes=true`;

export default function SignInPage() {
    const { t } = useTranslation();    
    const [params] = useSearchParams();

    const [signInByEmail, { isLoading, isError, error, isSuccess, data }] = useSignInByEmailMutation();
    const [email, setEmail] = useState('');
    const [isEmailError, setIsEmailError] = useState(false);    
    const [errorMessage, setErrorMessage] = useState('');    

    function emailChanged(value) {
        setEmail(value);
        setIsEmailError(false);
    }

    function keyPressed(event) {
        if (event.key === 'Enter') {
            verifyEmail();
        }
    }

    async function verifyEmail() {
        setIsEmailError(false);
        setErrorMessage('');
        signInByEmail(email);
    }

    useEffect(() => {
        if (!isError) return;
        if (error.status === 422) {    
            setErrorMessage(t('error_invalid_email'));                
            setIsEmailError(true);
        } else if (error.status === 429) {
            setErrorMessage(t('error_too_many_attempts'));            
        } else {
            setErrorMessage(t('error_unexpected'));
            console.log("Unexpected response status: ", error);
        }        
    }, [isError, error]);

    const searchParamsError = params.get('error');
    useEffect(() => {
        if (searchParamsError) {
            setErrorMessage(t(`error_${searchParamsError}`));
        }
    }, [searchParamsError]);

    if (isSuccess) {
        if (data.code != null) {
            // We skip email verification in debug mode
            return <Navigate to={`/email-verified/${data.code}`} />;
        } else {
            return <Navigate to='/verify-email' />;
        }
    }

    return (
        <TopLayout>
            <Logo small />
            <Heading size="6" align="center" my="2">{t('sign_in_title')}</Heading>

            <TextField placeholder={t('placeholder_email')} value={email} valueChange={emailChanged} onKeyPress={keyPressed} error={!!isEmailError}></TextField>
            {errorMessage && <Text color="red" mb="2" mt="-1">{errorMessage}</Text>}

            <Text my="2" style={{ textAlign: 'center' }}>{ t('agreement_part1') } <Link to='/terms-of-service'>{ t('agreement_part2') }</Link> { t('agreement_part3') } <Link to='/privacy-policy'>{ t('agreement_part4') }</Link>.</Text>

            <Button primary onClick={verifyEmail} isLoading={isLoading}>{t('action_continue')}</Button>

            <TextDivider>{t('or')}</TextDivider>

            <SocialButton to={facebookSignIn} icon={FacebookIcon}>{t('action_login_facebook')}</SocialButton>
            <SocialButton to={googleSignIn} icon={GoogleIcon}>{t('action_login_google')}</SocialButton>
        </TopLayout>
    );
}
