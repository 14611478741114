import { useSignInByFacebookMutation } from "@/api/authApi";
import { FullScreenLoader } from "@/components";
import useDidMount from "@/hooks/use-did-mount";
import { Navigate, useSearchParams } from "react-router-dom";

export default function SignInFacebookPage() {
    const [params] = useSearchParams();    
    const code = params.get('code');
    const facebookError = params.get('error') || params.get('error_message');

    const [signInByFacebook, { isError, isSuccess }] = useSignInByFacebookMutation();

    useDidMount(() => {        
        if (code) {
            signInByFacebook(code);
        }
    });

    if (isSuccess) {
        return <Navigate to='/' />;
    }

    if (facebookError || isError) {
        return <Navigate to='/sign-in?error=unable_to_login_facebook' />;
    }

    return <FullScreenLoader />;    
}