import { Avatar, NotificationCount, UserHeading } from "@/components";
import { Flex, Text } from "@radix-ui/themes";
import { useNavigate } from "react-router";
import "./chat-card.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLatestMessage, selectUnreadMessagesCount } from "@/api/chatSlice";
import { toLocaleDateTime } from "@/utils/datetime";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase-globals";

export default function ChatCard({ user, chat, active=false }) {    
    const navigate = useNavigate();
    const { t } = useTranslation();

    function onClick() {  
        logEvent(analytics, 'select_match');      
        navigate(`/chats/${chat.id}`)
    }

    const unreadMessages = useSelector(selectUnreadMessagesCount(chat.id));
    const latestMessage = useSelector(selectLatestMessage(chat.id)) || chat.last_message;    

    const displayBadge = unreadMessages > 0 || latestMessage == null;    

    return (
        <Flex width="100%" gap="5" align="center" onClick={onClick} className={'chat-card' + (active ? ' active' : '')} py="3" px="5">
            <Avatar user={user} />
            <Flex direction="column" grow="1" style={{ minWidth: 0 }}>                
                <UserHeading user={user} />
                <Text style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }} color={latestMessage?.text ? null : 'gray'}>{latestMessage?.text || t('help_first_message') }</Text>
            </Flex>    
            <Flex direction="column" justify="end" height="100%" mb="5">
                {displayBadge && <NotificationCount count={(unreadMessages > 0) ? unreadMessages : t('text_new_match') } size={ unreadMessages > 0 ? 32 : 42} offset={false} /> }          
                {!displayBadge && latestMessage && <Text size="2" style={{ whiteSpace: 'nowrap' }}>{toLocaleDateTime(latestMessage.timestamp || latestMessage.datetime)}</Text>}
            </Flex>                            
        </Flex>                                        
    );
}
