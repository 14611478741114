import { Heading, Text, Flex } from "@radix-ui/themes";
import CheckIcon from "@/assets/icons/check.svg";
import { Badge, Button } from "@/components";
import { TopLayout } from "@/layouts";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase-globals";

export default function SuccessBase({ name, ...props }) {
    const { t } = useTranslation();

    function onClick() {
        if (props.trackNextEvent) {
            logEvent(analytics, props.trackNextEvent);
        }
    }

    return (        
        <TopLayout>                  
            <Flex direction="column" gap="5" align="center" justify="center" style={{ height: '100%' }}>
                <Badge icon={props.icon ?? CheckIcon} />
                <Heading size="6" align="center" mt="4">{ t(`success_${name}_title`) }</Heading>                        
                <Text align="center">{ t(`success_${name}_subtitle`) }</Text>                                            
            </Flex>                        
            {props.extraSubtitle && <Text align="center" mb="3">{ t(t(`success_${name}_subtitle_2`)) }</Text>}
            {props.nextRoute && <Button primary to={props.nextRoute} onClick={onClick}>{ t(t(`success_${name}_action`)) }</Button>}
            {props.nextClick && <Button primary onClick={props.nextClick} disabled={props.loading}>{ t(t(`success_${name}_action`)) }</Button>}
        </TopLayout>
    );
}
