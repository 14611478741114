import { refreshAccessToken } from "@/api/base";
import { chatApi } from "@/api/chatApi";
import { uuid4 } from "@/utils/uuid";

const RETRY_DELAY_INITIAL = [1000, 2000];
const RETRY_MULTIPLY = 2;

class RetriableWebSocket {

  constructor(url) {
    this.url = url;
    this.accessToken = null;

    this.queue = [];
    this.socket = null;
    this.shouldRetry = true;
    this.retryTimeout = null;

    this._resetRetryDelay();

    this.onmessage = (e) => { };
    this.onclose = (e) => { };
    this.onopen = (e) => { };    
  }

  isConnected() {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }

  send(message) {
    this.socket.send(message);
  }

  enqueue(message) {
    this.queue.push(message);
  }

  connect(accessToken) {
    this.accessToken = accessToken;
    this.shouldRetry = true;
    this.reconnect();
  }

  reconnect() {    
    if (!this.socket) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = null;      
      this.shouldRetry = true;
      this._createNewSocket();
    }
  }

  close() {
    if (this.socket) this.socket.close();
  }

  _resetRetryDelay() {
    this.retryDelay = RETRY_DELAY_INITIAL[0] + Math.random() * (RETRY_DELAY_INITIAL[1] - RETRY_DELAY_INITIAL[0]);
  }

  _createNewSocket() {
    const self = this;
    
    this.socket = new WebSocket(this.url + '?access_token=' + this.accessToken);

    this.socket.onclose = function (e) {              
      self.socket = null;
      if (self.shouldRetry) {
        self.retryDelay *= RETRY_MULTIPLY;
        self.retryTimeout = setTimeout(() => self._createNewSocket(), self.retryDelay);
      }
      self.onclose(e);
    };

    this.socket.onopen = function (e) {
      self._resetRetryDelay();
      self.queue.forEach(item => self.socket.send(item));
      self.queue = [];
      self.onopen(e);
    };

    this.socket.onmessage = function (e) {
      const data = JSON.parse(e.data);      
      if (data.type == 'error' && data.status_code == 401) {
        self.shouldRetry = false;
      }
      self.onmessage(e);
    }
  }
}


export function createWebSocketMiddleware(options) {
  function createAction(name) {
    return { type: name, payload: {} };
  }

  return store => {
    const uuid = uuid4();

    const socket = new RetriableWebSocket(options.url);

    socket.onmessage = function (e) {
      const data = JSON.parse(e.data);
      if (data.type == 'error' && data.status_code == 401) {
        store.dispatch(createAction('connection/invalidAccessToken'));
        return;
      }

      if (data.uuid === uuid) return;      

      const receivedFrom = data.uuid;
      delete data.uuid;
      data.meta = { uuid: receivedFrom };

      store.dispatch(data);
    };

    socket.onclose = function (e) {        
      store.dispatch(createAction('connection/disconnected'));
    };

    socket.onopen = function (e) {
      store.dispatch(createAction('connection/connected'));
    };

    return next => action => {              
      const actionUuid = action.meta?.uuid;

      const wasNotSentYet = actionUuid === uuid || actionUuid == null;
      const matchesPrefix = options.actionPrefixes.some(prefix => action.type.startsWith(prefix));

      if (matchesPrefix && wasNotSentYet) {
        if (socket.isConnected()) {
          socket.send(JSON.stringify({ ...action, uuid }));
        } else {
          socket.enqueue(JSON.stringify(action));
        }
      }

      // TODO: This shouldn't be here
      if (action.type === 'chat/addMessage') {        
        store.dispatch(          
          chatApi.util.updateQueryData('getChats', undefined, (data) => {                        
            const chat = data.results.find(chat => chat.id == action.payload.chat_id);
            if (chat != null) {
              chat.last_change = action.payload.timestamp;
            }
            data.results.sort((a, b) => b.last_change.localeCompare(a.last_change));
          })
        );                
      }

      // TODO: This shouldn't be here
      if (action.type === 'chat/create') {
        store.dispatch(chatApi.util.invalidateTags(['Chats']))                
      }

      if (action.type === 'connection/connect') {        
        socket.connect(action.payload.accessToken);
      }

      if (action.type === 'connection/disconnect') {
        socket.close();
      }

      if (action.type === 'connection/retry') {
        socket.reconnect();
      }

      if (action.type == 'connection/invalidAccessToken') {
        refreshAccessToken().then(newAccessToken => {
            if (newAccessToken != null) {                
                socket.connect(newAccessToken);
            } else {
                // TODO: Find more pretty way to do this
                window.location.href= '/welcome';
            }
        });        
      }
            
      return next(action);
    }
  }
}
