import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './base';

export const jokeApi = createApi({
    reducerPath: 'jokeApi',
    baseQuery: customFetchBase, 
    tagTypes: ['Jokes', 'JokeCounter'],
    endpoints: (builder) => ({
        getJokeCounter: builder.query({
            query: () => ({
                url: '/votable-content/joke/counter',
                method: 'GET',             
            }),
            providesTags: ['JokeCounter'],
        }),

        getUnvotedJoke: builder.query({
            query: ({ language, count }) => ({
                url: `/votable-content/joke/unvoted?language=${language}&count=${count}`,
                method: 'GET',             
            }),
            providesTags: ['Jokes'],
        }),

        voteJoke: builder.mutation({
            query: ({ contentId, voteType }) => ({
                url: `/votable-content/joke/${contentId}/vote`,                
                method: 'POST',
                body: { vote_type: voteType },
            }),
            invalidatesTags: ['Jokes', 'JokeCounter']
        }),
    }),
})

export const {
    useGetJokeCounterQuery,
    useGetUnvotedJokeQuery,
    useVoteJokeMutation,
    endpoints
} = jokeApi;
