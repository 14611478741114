import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import keyBy from 'lodash/keyBy';
import customFetchBase from "./base";


// TODO: Maybe async thunk for fetching chats
// TODO: Unread messages count
const initialState = {
    chats: {},    
    chatDetails: {},
    loading: false,
};

export const getMessages = createAsyncThunk(
    'chat/getMessages',
    async ({ chatId, olderThan, limit }) => {        
        const response = await customFetchBase({ url: `/chats/${chatId}/messages`, params: { older_than: olderThan, limit: limit } }, '', '');        
        return { messages: response.data };
    }
);

export const readMessages = createAsyncThunk(
    'chat/readMessages',
    async ({ chatId }) => {               
        const response = await customFetchBase({ url: `/chats/${chatId}/messages/read`, method: 'POST' }, '', '');        
        return { data: response.data };
    }
);

function ensureChatDetailExists(state, chatId) {
    if (!(chatId in state.chatDetails)) {
        state.chatDetails[chatId] = { 
            messages: null, 
            queued_messages: [],
            unread_messages: 0,            
            loading: true, 
            fetching: false,
            error: false,
        };
    }
}

function addMessageToCorrectPosition(messages, message) {    
    let i = messages.length - 1;
    while (i >= 0 && message.timestamp < messages[i].timestamp) {
        i--;
    }
    // Simplification we assume the timestamp won't change to avoid full scan
    if (i >= 0 && message.id == messages[i].id) {
        return false;
    }
    messages.splice(i + 1, 0, message);                
    return true;
}

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        chatsLoading(state, action) {            
            state.loading = true;            
        },

        chatsUpdated(state, action) {                        
            state.chats = keyBy(action.payload, 'id');
            state.loading = false;
        },

        addMessage(state, action) {            
            const message = action.payload;
            ensureChatDetailExists(state, message.chat_id);            
            let chatDetail = state.chatDetails[message.chat_id];            

            if (chatDetail.messages == null) {                
                chatDetail.queued_messages.push(message);
                chatDetail.unread_messages += 1;
            } else if (addMessageToCorrectPosition(chatDetail.messages, message)) {                
                chatDetail.unread_messages += 1;
            }
        },

        addUnreadMessages(state, action) {
            const chatId = action.payload.chat_id;
            ensureChatDetailExists(state, chatId);
            state.chatDetails[chatId].unread_messages += action.payload.unread_messages;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMessages.pending, (state, action) => {                         
            const chatId = action.meta.arg.chatId;
            ensureChatDetailExists(state, chatId);            
            const chatDetail = state.chatDetails[chatId];
            if (chatDetail.messages == null) {
                chatDetail.loading = true;
            } else {
                chatDetail.fetching = true;
            }
            chatDetail.error = false;
        });
        builder.addCase(getMessages.fulfilled, (state, action) => {
            const chatId = action.meta.arg.chatId;            
            ensureChatDetailExists(state, chatId);
            const chatDetail = state.chatDetails[chatId];            

            if (!action.payload.messages) {
                // TODO: Investigate how this can even happen
                chatDetail.loading = false;
                chatDetail.fetching = false;
                chatDetail.error = true;
                return;
            }

            if (action.meta.arg.olderThan != null) {                                
                chatDetail.messages = [...action.payload.messages.reverse(), ...chatDetail.messages];                
            } else {
                chatDetail.messages = action.payload.messages.reverse();                
            }            

            chatDetail.queued_messages.forEach(message => addMessageToCorrectPosition(chatDetail.messages, message));
            chatDetail.queued_messages = [];

            chatDetail.loading = false;
            chatDetail.fetching = false;
            chatDetail.error = false;
        });
        builder.addCase(getMessages.rejected, (state, action) => {            
            const chatId = action.meta.arg.chatId;
            ensureChatDetailExists(state, chatId);
            const chatDetail = state.chatDetails[chatId];            
            chatDetail.loading = false;
            chatDetail.fetching = false;
            chatDetail.error = true;
        });
        builder.addCase(readMessages.pending, (state, action) => {            
            const chatId = action.meta.arg.chatId;
            ensureChatDetailExists(state, chatId);
            state.chatDetails[chatId].unread_messages = 0;
        });        
    },
});

export const reducer = chatSlice.reducer;

export const { 
    chatsLoading, 
    chatsUpdated,    
    addMessage,
    addUnreadMessages,
} = chatSlice.actions;

export const chatReducerPath = chatSlice.name;
export const chatReducer = chatSlice.reducer;

export const selectConversations = state => Object.values(state.chat.conversations).sort((a, b) => {
    return b.last_message?.timestamp - a.last_message?.timestamp;
});

const defaultChatDetail = { messages: null, loading: true };
export const selectMessages = chatId => state => state.chat.chatDetails[chatId] ?? defaultChatDetail;

export const selectUnreadMessagesCount = chatId => state => state.chat.chatDetails[chatId]?.unread_messages ?? 0;
export const selectLatestMessage = chatId => state => {
    const chat = state.chat.chatDetails[chatId];
    const latestQueued = chat?.queued_messages?.[chat?.queued_messages?.length - 1] ?? null;
    const latestLoaded = chat?.messages?.[chat?.messages?.length - 1] ?? null;
    return latestQueued || latestLoaded;
};
