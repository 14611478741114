import { Heading, Text, Flex } from "@radix-ui/themes";
import EmailIcon from "@/assets/icons/email.svg";
import { Badge, Link, Logo } from "@/components";
import { TopLayout } from "@/layouts";
import { useTranslation } from "react-i18next";

export default function VerifyEmailPage() {
    const { t } = useTranslation();
    return (
        <TopLayout>
            <Logo small />            
            <Flex direction="column" gap="5" align="center" justify="center" style={{ height: '100%' }}>
                <Badge icon={EmailIcon} />
                <Heading size="6" align="center" mt="4">{t('verify_email_title')}</Heading>                        
                <Text align="center">{t('verify_email_subtitle')}</Text>                                            
            </Flex>
            <Text align="center" size="2">{t('no_email_question')} <Link to="/sign-in">{t('action_resent')}</Link></Text>                        
        </TopLayout>
    );
}
