export function uuid4() {
    let result = '';
    const mask = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    let randomBytes = Math.random() * 0xffffffff | 0;

    for (let i = 0; i < mask.length; i++) {
        var c = mask[i];
        var r = randomBytes & 0xf;
        var v = c == 'x' ? r : (r & 0x3 | 0x8);
        result += (c == '-' || c == '4') ? c : v.toString(16);
        randomBytes = i % 8 == 0 ? Math.random() * 0xffffffff | 0 : randomBytes >> 4;
    }

    return result;
}
