import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './base';

export const lifestyleApi = createApi({
    reducerPath: 'lifestyleApi',
    baseQuery: customFetchBase, 
    tagTypes: ['Lifestyles', 'LifestyleCounter'],
    endpoints: (builder) => ({
        getLifestyleCounter: builder.query({
            query: () => ({
                url: '/votable-content/lifestyle/counter',
                method: 'GET',             
            }),
            providesTags: ['LifestyleCounter'],
        }),

        getUnvotedLifestyle: builder.query({
            query: ({ language, count }) => ({
                url: `/votable-content/lifestyle/unvoted?language=${language}&count=${count}`,
                method: 'GET',             
            }),
            providesTags: ['Lifestyles'],
        }),

        voteLifestyle: builder.mutation({
            query: ({ contentId, voteType }) => ({
                url: `/votable-content/lifestyle/${contentId}/vote`,                
                method: 'POST',
                body: { vote_type: voteType },
            }),
            invalidatesTags: ['Lifestyles', 'LifestyleCounter']
        }),
    }),
})

export const {
    useGetLifestyleCounterQuery,
    useGetUnvotedLifestyleQuery,
    useVoteLifestyleMutation,
    endpoints
} = lifestyleApi;
