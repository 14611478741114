import { Spinner } from "@/components";
import { Button as UIButton } from "@radix-ui/themes";
import { Link as RouterLink } from "react-router-dom";

export default function Button(props) {    
    const { to, primary, children, style, isLoading, plain, ...rest } = props;
    const variant = primary ? 'solid' : 'soft';    
    const addStyle = primary ? { fontSize: '17px' } : { 
        backgroundColor: plain ? 'transparent' : '#fff', 
        color: plain ? 'var(--gray-a11)' : 'var(--gray-12)',
        fontSize: '16px', 
    };
    const wrappedChildren = <WrappedChildren isLoading={isLoading} children={children} />;
    const buttonStyle = { width: '100%', height: '56px', fontWeight: '600', position: 'relative' };
    return (
        <UIButton asChild={!!to} radius="full" size="4" style={{ ...buttonStyle, ...style, ...addStyle }} {...rest} variant={variant} disabled={isLoading || rest.disabled}>                        
            { to && <RouterLink to={ to }>{ wrappedChildren }</RouterLink> || wrappedChildren }            
        </UIButton>  
    );    
}

function WrappedChildren({ isLoading, children }) {
    const spinnerStyle = { position: 'absolute', left: '16px' };    
    return isLoading ? <><Spinner size={24} style={spinnerStyle} /> { children }</> : children;
}