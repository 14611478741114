import { SuccessBase } from "@/components";
import ChatList from "@/components/chat-list";
import BellIcon from "@/assets/icons/bell.svg";
import { useTranslation } from "react-i18next";
import { requestAndStoreMessagingToken } from "@/middlewares/notification-token-middleware";
import { useEffect, useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase-globals";

export default function ChatsPage() {
    return (        
        <ChatList emptyList={ <NoChats /> } bordered={true} />        
    );
}

function NoChats() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    async function requestPermission() {
        setLoading(true);
        try {
            await requestAndStoreMessagingToken();
        } catch (error) { }        
        setLoading(false);

        if (Notification.permission === 'granted') {
            logEvent(analytics, 'turn_on_notifications');
        }
        setPermission(Notification.permission);
    }

    const [permission, setPermission] = useState(Notification.permission);    
    useEffect(() => {
        logEvent(analytics, 'data_collection_done');
    }, [permission]);

    if (permission === 'granted' || permission === 'denied') {
        return <SuccessBase name={`jokes_${permission}`} />;
    }

    return <SuccessBase name="notification" icon={BellIcon} nextClick={requestPermission} extraSubtitle={true} loading={loading} />;
}
