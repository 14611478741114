import { useGetProfileQuery } from "@/api/profileApi";
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";
import FullScreenLoader from "./full-screen-loader";
import store from '@/store';
import { connect } from "@/api/connectionSlice";
import { getAccessToken } from "@/api/base";
import { useEffect } from "react";
import ProfileButton from "./profile-button";

export const allowedRoutesWithoutAge = {'/': 1, '/registration': 1, '/registration/0': 1, '/registration/1': 1};

export default function RequireUser() {        
    const { isLoading, isFetching, data, ...rest } = useGetProfileQuery();    
    const location = useLocation();

    useEffect(() => {
        if (data != null) {
            store.dispatch(connect({accessToken: getAccessToken()}));
        }
    }, [data]);
    
    if (isLoading || isFetching) {
        return <FullScreenLoader />;  
    }      

    if (data == null) {
        return <Navigate to ="/welcome" />;
    }
        
    if (data.birthday == null && !(location.pathname in allowedRoutesWithoutAge)) {
        return <Navigate to="/registration" />;
    }        

    return <>        
        { !location.pathname.startsWith('/chat') && <ProfileButton user={data} topRightCorner={true} /> }
        <Outlet />
    </>;
}