import { Box, Flex, Heading, Text } from "@radix-ui/themes";
import { Button, Logo, Link } from "@/components";
import { useTranslation } from 'react-i18next';
import Background from '@/assets/welcome-image.webp';
import { analytics } from "@/firebase-globals";
import { logEvent } from "firebase/analytics";

export default function WelcomePage() {
    const { t } = useTranslation();

    function trackSignIn() {
        logEvent(analytics, 'sign_in');        
    }

    function trackSignUp() {
        logEvent(analytics, 'sign_up');        
    }

    return (
        <Flex height="100%" width="100%" justify="center" align="center" py="5">                                    
            <Flex align="center" direction={{"initial": "column", "sm": "row"}} gap={{"initial": "0", "sm": "3"}} style={{ 
                width: '100%',     
                maxWidth: '1000px',                         
                maxHeight: '800px',
                height: '100%',
                margin: '0 auto' 
            }}>
                <Box display={{"initial": "block", "sm": "none"}}><Logo /></Box>
                <WelcomeImage />
                
                <Flex direction="column" px="7" gap="5" width="100%">
                    <Flex display={{"initial": "none", "sm": "flex"}} justify="center" mb="4"><Logo /></Flex>
                    <Heading size="6" align="center">{t('welcome_title')}</Heading>
                    <Text align="center" mb="4">{t('welcome_subtitle')}</Text>                            
                    <Button primary to="/sign-in" onClick={trackSignIn}>{t('action_sign_in')}</Button>                

                    <Text align="center" size="2">{t('no_account_question')} <Link to="/sign-in" onClick={trackSignUp}>{t('action_sign_up')}</Link></Text>                                    
                </Flex>
            </Flex>            
        </Flex>
    );
}

function WelcomeImage() {
    return (        
        <div style={{ 
            width: '100%', 
            height: '100%', 
            background: 'url("' + Background + '") 50% 50% no-repeat',
            backgroundSize: 'contain',            
            margin: 'calc(0 0 calc(-1 * var(--space-5)) 0'
        }}></div>
    );
}
