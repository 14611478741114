import { useGetChatQuery, useSendMessageMutation } from "@/api/chatApi";
import { useGetProfileQuery } from "@/api/profileApi";
import { Avatar, BackButton, FullScreenLoader, FullScreenLoadingError, NewMessages, Spinner, TextArea, TextField, UserHeading } from "@/components";
import { Box, Flex, Popover, Button as UIButton } from "@radix-ui/themes";
import { useParams } from "react-router-dom";
import './chat-page.scss';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessages, readMessages, selectMessages } from "@/api/chatSlice";
import useIsInViewport from "@/hooks/use-is-in-viewport";
import useRememberScrollBottom from "@/hooks/use-remember-scroll-bottom";
import EmojiPicker, { Categories } from "emoji-picker-react";
import EmoticonIcon from "@/assets/icons/emoticon.svg";
import { formatTextAsHtml } from "@/utils/formatting";
import { useTranslation } from "react-i18next";
import { toLocaleTime } from "@/utils/datetime";
import ChatList from "@/components/chat-list";

export default function ChatPage() {
    const routeParams = useParams();
    const chatId = routeParams.id;

    // No need to handle errors here, we use cached value
    // if it's not available, user wouldn't get past the RequireUser guard
    const { data: myProfile } = useGetProfileQuery();        
    const { data: chat, isLoading, isFetching, refetch } = useGetChatQuery(chatId);

    const user = chat ? chat.users.filter((user) => user.id !== myProfile.id)[0] : undefined;    
    
    return (
        <Flex width="100%" height="100%">
            <Box display={{ initial: "none", md: "block" }} style={{ borderRight: '1px solid var(--gray-a6)', width: '30%', maxWidth: '500px' }}>
                <ChatList active={chatId} />
            </Box>
            { (isLoading || isFetching) ? <FullScreenLoader /> : (
              chat ? 
                <Chat chatId={chatId} user={user} myProfile={myProfile} /> :
                <FullScreenLoadingError onRetry={refetch} />                 
            )}
        </Flex>
    );
}

function Chat({ chatId, user, myProfile }) {
    return (
        <Flex direction="column" width="100%" height="100%">
            <Flex width="100%" justify="between" align="center" py="3" px="4" style={{ borderBottom: '1px solid var(--gray-a5)' }}>
                <BackButton to='/chats' />
                <UserHeading user={user} />
                <Avatar user={user} size="3" />
            </Flex>
            <Messages chatId={chatId} currentUserId={myProfile.id} />
            <SendMessageForm chatId={chatId} />
        </Flex>
    );
}

function Messages({ chatId, currentUserId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => { dispatch(getMessages({ chatId })) }, [chatId]);

    const { messages: data, unread_messages: newMessages, loading: isLoading, error } = useSelector(selectMessages(chatId));
    const oldestMessageTimestamp = (data && data.length > 0) ? data[0].timestamp : null;

    const containerRef = useRef();
    const messagesEndRef = useRef();
    const messagesStartRef = useRef();

    const [wasScrolledToBottom, setWasScrolledToBottom] = useState(false);

    useEffect(() => {
        setWasScrolledToBottom(false);
    }, [chatId]);

    useEffect(() => {
        if (!wasScrolledToBottom) {            
            readNewMessages();            

            // To prevent the loading older messages after first load when messagesStarRef is still in viewport
            setTimeout(() => setWasScrolledToBottom(true), 50);
        }
    }, [isLoading, wasScrolledToBottom]);

    const isScrolledToTop = useIsInViewport(messagesStartRef);
    const isScrolledToBottom = useIsInViewport(messagesEndRef);
    const shouldLoadOlderMessages = isScrolledToTop && wasScrolledToBottom;

    const prevIsScrolledToBottom = useRef(null);
    useEffect(() => {
        setTimeout(() => prevIsScrolledToBottom.current = isScrolledToBottom);
    }, [isScrolledToBottom]);

    useEffect(() => {
        if (!isScrolledToBottom && prevIsScrolledToBottom.current) {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            dispatch(readMessages({ chatId }));
        }
    }, [data, isScrolledToBottom]);

    const rememberScrollPosition = useRememberScrollBottom(data, containerRef);
    useEffect(() => {
        if (oldestMessageTimestamp != null && shouldLoadOlderMessages) {
            rememberScrollPosition();
            dispatch(getMessages({ chatId, olderThan: oldestMessageTimestamp }));
        }
    }, [shouldLoadOlderMessages]);
    
    useEffect(() => {
        if (isScrolledToBottom && newMessages > 0) {
            dispatch(readMessages({ chatId }));
        }
    }, [isScrolledToBottom, newMessages]);

    function readNewMessages() {
        dispatch(readMessages({ chatId }));
        messagesEndRef.current?.scrollIntoView();
    }

    if (isLoading) {
        return <Flex height="100%" align="center" justify="center"><Spinner size="64" /></Flex>;
    }

    if (error) {
        return <FullScreenLoadingError onRetry={() => dispatch(getMessages({ chatId }))} />;
    }

    function getDirection(message) {        
        return message.user_id === currentUserId ? 'outgoing' : 'incoming';
    }

    const messages = data.map((message, i) => {
        const direction = getDirection(message);
        const nextDirection = (i + 1 < data.length) ? getDirection(data[i + 1]) : null;
        const displayTime = nextDirection != direction;
        return { ...message, direction, displayTime };
    });

    function createDateDelimiter(message) {
        const localizedDate = new Date(message.timestamp + 'Z').toLocaleDateString();
        const isToday = localizedDate === new Date().toLocaleDateString();
        return { type: 'delimiter', text: isToday ? t('date_today') : localizedDate, id: `delimiter_${message.id}` };
    }

    const messagesWithDateDelimiters = messages.reduce((acc, message, i) => {
        if (i === 0) {
            acc.push(createDateDelimiter(message));
        } else {
            const previousMessage = messages[i - 1];

            const previousDate = new Date(previousMessage.timestamp + 'Z').toDateString();
            const currentDate = new Date(message.timestamp + 'Z').toDateString();

            if (previousDate !== currentDate) {
                acc.push(createDateDelimiter(message));
            }
        }

        acc.push({ type: 'message', id: `message_${message.id}`, message });
        return acc;
    }, []);        

    return (
        <Flex direction="column" width="100%" py="3" px="4" height="100%" style={{ maxHeight: '100%', overflowY: 'auto' }} ref={containerRef}>
            <div ref={messagesStartRef} />                
            {messagesWithDateDelimiters.length === 0 ? <div style={{ textAlign: 'center', margin: 'var(--space-4)', color: 'var(--gray-11)' }}>{ t('help_first_message') }</div> : <>                
                {messagesWithDateDelimiters.map((item) => <MessageOrDelimiter key={item.id} data={item} />)}                
            </>}
            <div ref={messagesEndRef} />
            <NewMessages count={newMessages} onClick={readNewMessages} />
        </Flex>
    );
}

function MessageOrDelimiter({ data }) {    
    if (data.type === 'delimiter') {
        return <div style={{ textAlign: 'center', margin: 'var(--space-4)', color: 'var(--gray-11)' }}>{data.text}</div>;
    } else {
        return <Message message={data.message} />;
    }    
}

function Message({ message }) {        
    const className = message.direction === "outgoing" ? ' outgoing' : ' incoming';
    return (<>
        <Box className={'chat-message' + className}>{formatTextAsHtml(message.text)}</Box>
        {message.displayTime && <div className={'chat-timestamp' + className}>{toLocaleTime(message.timestamp)}</div>}
    </>);
}

function SendMessageForm({ chatId }) {
    const { t } = useTranslation();
    const [sendMessageMutation, { }] = useSendMessageMutation();

    const [message, setMessage] = useState('');
    const inputRef = useRef();

    function addEmoji(emoji) {
        const cursor = inputRef.current?.selectionStart;
        const text = message.slice(0, cursor) + emoji + message.slice(cursor);
        setMessage(text);
    }

    function sendMessage() {
        if (!message) return;

        sendMessageMutation({ chatId, text: message });
        setMessage('');
        inputRef.current?.focus();
    }

    function onKeyPress(e) {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    }

    useEffect(() => {
        inputRef.current?.focus();
    }, [inputRef]);

    return (
        <Flex width="100%" align="stretch" style={{ borderTop: '1px solid var(--gray-a5)' }} p="3" gap="3">
            <Box width="100%" position="relative">
                <TextArea
                    placeholder={t('placeholder_type_message')}
                    value={message} onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={onKeyPress}
                    forwardedRef={inputRef}
                />
                <EmojiPickerButton onAdd={addEmoji} />
            </Box>
            <UIButton radius="full" size="4" style={{ height: '56px', fontWeight: '600' }} onClick={sendMessage}>{t('action_send')}</UIButton>
        </Flex>
    )
}

function EmojiPickerButton({ onAdd }) {
    const { t } = useTranslation();
    const categories = [
        { category: Categories.SUGGESTED, name: t('emoji_category_suggested') },
        { category: Categories.SMILEYS_PEOPLE, name: t('emoji_category_smileys_people') },
        { category: Categories.ANIMALS_NATURE, name: t('emoji_category_animals_nature') },
        { category: Categories.FOOD_DRINK, name: t('emoji_category_food_drink') },
        { category: Categories.TRAVEL_PLACES, name: t('emoji_category_travel_places') },
        { category: Categories.ACTIVITIES, name: t('emoji_category_activities') },
        { category: Categories.OBJECTS, name: t('emoji_category_objects') },
        { category: Categories.SYMBOLS, name: t('emoji_category_symbols') },
        { category: Categories.FLAGS, name: t('emoji_category_flags') },
    ];

    return (
        <Popover.Root>
            <Popover.Trigger>
                <img src={EmoticonIcon} width="30" height="30" style={{ position: 'absolute', cursor: 'pointer', zIndex: 2, right: 14, top: 13 }} />
            </Popover.Trigger>
            <Popover.Content style={{ padding: 0 }}>
                <EmojiPicker
                    emojiStyle="native"
                    onEmojiClick={(e) => onAdd(e.emoji)}
                    searchPlaceholder={t('placeholder_search')}
                    categories={categories}
                    previewConfig={{ showPreview: false }}
                />
            </Popover.Content>
        </Popover.Root>
    );
}