import { useGetProfileQuery } from "@/api/profileApi";
import { Navigate } from "react-router-dom";
import { stepNames as registrationStepNames } from "./registration-page";
import { stepNames as basicInfoStepNames } from "./basic-info-page";

export default function NavigateToLastStepPage() {
    // No need to check for loading state, because the query will always be in the cache
    const { data } = useGetProfileQuery();          
    
    if (registrationStepNames.some(name => data[name] == null)) {
        return <Navigate to="/registration" replace={true} />;
    }

    if (data.birthday == null) {
        return <Navigate to="/registration" replace={true} />;
    }    

    if (basicInfoStepNames.some(name => data[name] == null)) {
        return <Navigate to="/basic-info" replace={true} />;
    }

    return <Navigate to="/lifestyle?ff=1" replace={true} />;    
}