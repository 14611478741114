import { configureStore } from '@reduxjs/toolkit'
import { authApi } from './api/authApi';
import { profileApi } from './api/profileApi';
import { lifestyleApi } from './api/lifestyleApi';
import { followupApi } from './api/followupApi';
import { jokeApi } from './api/jokeApi';
import { chatApi } from './api/chatApi';
import { createWebSocketMiddleware } from './middlewares/websocket-middleware';
import { connectionSlice } from './api/connectionSlice';
import { authSlice } from './api/authSlice';
import { chatReducer, chatReducerPath } from './api/chatSlice';
import { notificationApi } from './api/notificationsApi';
import { listenerMiddleware } from './middlewares/notification-token-middleware';


export const webSocketMiddleware = createWebSocketMiddleware({
  url: import.meta.env.VITE_BACKEND_WS_URL,
  actionPrefixes: []
});


export default configureStore({
  reducer: {        
    [authApi.reducerPath]: authApi.reducer,
    auth: authSlice.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [lifestyleApi.reducerPath]: lifestyleApi.reducer,
    [followupApi.reducerPath]: followupApi.reducer,
    [jokeApi.reducerPath]: jokeApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,    
    [chatReducerPath]: chatReducer,    
    connection: connectionSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat([
        authApi.middleware,
        profileApi.middleware,
        lifestyleApi.middleware,
        followupApi.middleware,
        notificationApi.middleware,
        jokeApi.middleware,
        chatApi.middleware,
        webSocketMiddleware,
      ]),
});
