import { TextField, UniqueChoice, MultipleChoice } from "@/components";
import { useGetProfileQuery, useUpdateProfileMutation } from "@/api/profileApi";
import { useTranslation } from "react-i18next";
import Birthday from "@/components/birthday";
import { minLength, notEmptyString, validBirthday } from "@/validators";
import WizardBase from "@/components/wizard-base";
import { useEffect } from "react";

const steps = [
    { name: 'first_name', subtitle: 'subtitle_public', content: (p) => <FirstName {...p} />, validator: notEmptyString },
    { name: 'birthday', subtitle: 'subtitle_age', content: (p) => <Birthday {...p} />, validator: validBirthday },
    { name: 'gender', subtitle: 'subtitle_public', content: (p) => <Gender {...p} />, validator: notEmptyString },
    { name: 'languages', subtitle: 'subtitle_languages', content: (p) => <Language {...p} />, validator: minLength(1) }
];

export const stepNames = steps.map(step => step.name);

export default function RegistrationPage() {
    return <WizardBase 
        name="registration" 
        steps={steps}
        getHook={useGetProfileQuery} 
        patchHook={useUpdateProfileMutation} 
    />;
}

function FirstName(props) {
    const { t } = useTranslation();
    return <TextField placeholder={t('placeholder_first_name')} {...props} />;
}

function Gender(props) {
    return <UniqueChoice choices={['male', 'female', 'non_binary']} {...props} />
}

function Language(props) {    
    const { i18n } = useTranslation()        

    useEffect(() => {        
        if (props.value === undefined || props.value === null || props.value === '') {   
            props.valueChange([i18n.resolvedLanguage]);
        }
    }, [props, i18n.resolvedLanguage]);

    return <MultipleChoice choices={['en', 'sk', 'cs']} {...props} />
}
