import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';


const baseUrl = import.meta.env.VITE_BACKEND_BASE_URL;
const mutex = new Mutex();

let accessToken = null;

const baseQuery = fetchBaseQuery({ baseUrl });

async function customFetchBase(args, api, extraOptions) {    
    if (typeof args !== 'string' && args.public) {
        return await baseQuery(args, api, extraOptions);
    }

    await mutex.waitForUnlock();    

    if (accessToken != null) {
        let result = await baseQueryWithAuthHeader(args, api, extraOptions);
        if (result.error?.status !== 401) {
            return result;
        }        
        setAccessToken(null);
    }

    if (mutex.isLocked()) {
        await mutex.waitForUnlock();
        return await baseQueryWithAuthHeader(args, api, extraOptions);
    }

    const release = await mutex.acquire();
    try {
        const refreshResult = await baseQuery(
            { url: 'users/sign-in/refresh', method: 'POST', credentials: 'include' },
            api,
            extraOptions
        );
        if (refreshResult.error?.status !== 401) {
            setAccessToken(refreshResult.data?.access_token);
            return await baseQueryWithAuthHeader(args, api, extraOptions);
        } else {
            return refreshResult;
        }
    } finally {
        release();
    }    
}

function baseQueryWithAuthHeader(args, api, extraOptions) {
    const dictArgs = (typeof args == 'string') ? { url: args } : args;    
    const enrichedArgs = { ...dictArgs, headers: { ...dictArgs.headers, 'Authorization': `Bearer ${accessToken}` } };
    return baseQuery(enrichedArgs, api, extraOptions);
}

export function setAccessToken(newAccessToken) {
    accessToken = newAccessToken;    
}

export function getAccessToken() {
    return accessToken;
}

export async function refreshAccessToken() {
    setAccessToken(null);
    if (mutex.isLocked()) {
        await mutex.waitForUnlock();
        return accessToken;
    }

    const release = await mutex.acquire();
    try {
        const refreshResult = await baseQuery({ url: 'users/sign-in/refresh', method: 'POST', credentials: 'include' }, {}, {});
        if (refreshResult.error?.status !== 401) {
            console.log("Refreshed access token for websocket!");
            setAccessToken(refreshResult.data?.access_token);            
        } else {
            console.log("Refresh for websocket failed!");
        }
    } finally {
        release();
    }    
    return accessToken;
}

export default customFetchBase;

