import { useGetJokeCounterQuery, useGetUnvotedJokeQuery, useVoteJokeMutation } from "@/api/jokeApi";
import { VotableContent } from "@/components";
import { assetsUrl } from "@/utils/assets";
import { Flex, Text } from "@radix-ui/themes";


export default function JokesPage() {    
    return (
        <VotableContent 
            id="joke"
            getCounterHook={useGetJokeCounterQuery} 
            getUnvotedHook={useGetUnvotedJokeQuery} 
            voteHook={useVoteJokeMutation} 
            renderContent={(content) => <Joke content={content} /> }
            completeUrl='/chats' 
            actionPrefix='joke_'
            fullscreen={true}
        />        
    );
}

function Joke({ content }) {
    if (content.type == 'text') {
        return <TextJoke content={content} />;
    } else {
        return <ImageJoke content={content} />;
    }
}

function TextJoke({ content }) {    
    const background = 'linear-gradient(var(--accent-9), var(--accent-11))';
    const videoSrc = assetsUrl('/videos/' + content.filename);
    return (
        <Flex align="center" justify="center" p="7" style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div style={{ position: 'fixed', left: '0', top: 0, width: '100%', height: '100%', zIndex: 1, background: background }}></div>            
            {content.filename && 
                <video key={videoSrc} autoPlay muted loop id="myVideo" style={{ position: 'fixed', objectFit: 'cover', left: '0', top: 0, width: '100%', height: '100%', zIndex: 2 }}>
                    <source src={videoSrc} type="video/mp4" />
                </video>
            }
            <div style={{ position: 'fixed', left: '0', top: 0, width: '100%', height: '100%', zIndex: 3, boxShadow: 'inset rgba(0, 0, 0, 0.4) 0px 10px 100px 10px' }}></div>
            <Text style={{ filter: "url('#goo')", boxDecorationBreak: 'clone', display: 'inline', color: '#fff', backgroundColor: 'var(--gray-a12)', padding: '0.5rem 1rem', zIndex: 4 }} size="7" weight="bold" align="center">{content.text}</Text>                            
            <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <filter id="goo"><feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />    
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                        <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
                    </filter>
                </defs>
            </svg>
        </Flex>
    );    
}


function ImageJoke({ content }) {
    const imageSrc = assetsUrl('/images/' + content.filename);
    return <>
        <div style={{ position: 'fixed', left: '0', top: 0, width: '100%', height: '100%', zIndex: -1, backgroundColor: '#000' }}></div>
        <img src={ imageSrc } style={{ width: '100%', height: '100%', objectFit: 'contain', paddingTop: '40px', boxSizing: 'border-box' }} />
    </>;
}
