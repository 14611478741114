import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './base';

export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    baseQuery: customFetchBase, 
    tagTypes: [],
    endpoints: (builder) => ({
        addToken: builder.mutation({
            query: ({ token, language }) => ({
                url: '/notifications/tokens',
                method: 'POST',     
                body: { token, language },
            }),            
        }),

        removeToken: builder.mutation({
            query: ({ token }) => ({
                url: '/notifications/tokens',
                method: 'DELETE',       
                body: { token },      
            }),            
        }),
    }),
})

export const {    
    useAddTokenMutation,
    useRemoveTokenMutation,    
    endpoints
} = notificationApi;

