import { MultipleChoice, TextField, UniqueChoice } from "@/components";
import { useTranslation } from "react-i18next";
import { useGetFollowupsQuery } from "@/api/followupApi";
import FullScreenLoader from "@/components/full-screen-loader";
import i18n from "@/i18n";
import WizardBase from "@/components/wizard-base";
import { useGetProfileQuery, useUpdateProfileMutation } from "@/api/profileApi";
import { alwaysValid, minLength, notEmptyString } from "@/validators";


export default function FollowUpPage() {
    const { isLoading, data } = useGetFollowupsQuery();

    if (isLoading) return <FullScreenLoader />;

    return <LoadedFollowUpPage data={data} />
}

function getValidator(type) {
    if (type == 'unique_choice') return notEmptyString;
    if (type == 'multiple_choice') return minLength(1);
    if (type == 'open_question') return notEmptyString;
    return alwaysValid;
}

function getOpenQuestion(name, translated_name, placeholder) {
    const data = {
        type: 'open_question',
        translation: { placeholder }
    };
    return {
        type: 'open_question',
        name,
        translated_name,
        subtitle: 'subtitle_private',        
        content: (p) => <Question data={data} {...p} />, 
        validator: getValidator(data.type)        
    };
}

function LoadedFollowUpPage({ data }) {
    const { t } = useTranslation();            

    // TODO: Better validator
    const steps = [...data.map(
        row => {
            const data = {
                type: row.type,
                slug: row.unique_id,
                choices: row.choices,
                translation: row[i18n.resolvedLanguage],    
            };
            return { 
                name: row.unique_id,
                translated_name: data.translation.name, 
                subtitle: 'subtitle_private', 
                content: (p) => <Question data={data} {...p} />, 
                validator: getValidator(data.type)
            };
        }), 
        getOpenQuestion('love', t('wizard_love'), t('placeholder_specific')),
        getOpenQuestion('hate', t('wizard_hate'), t('placeholder_specific'))
    ];

   return <WizardBase 
        name="follow-up" 
        steps={steps}
        getHook={useGetProfileQuery} 
        patchHook={useUpdateProfileMutation} 
        fastForwardCompelteUrl="/jokes?ff=1"
    />;
}

function Question({ data, ...props }) {        
    if (data.type == "unique_choice") {
        return <UniqueChoice 
            choices={data.choices} 
            translations={data.translation.choices} 
            {...props}
        />;
    }

    if (data.type == "multiple_choice") {
        return <MultipleChoice 
            choices={data.choices} 
            translations={data.translation.choices} 
            {...props}
        />;
    }

    if (data.type == "open_question") {
        return <TextField placeholder={data.translation.placeholder || ''} {...props} />;
    }

    return (
        <>An unexpected error has occurred (Unsupported question type)</>        
    );
}
