const REGEX_URL_MATCHING = /(https?:\/\/[^\s]+|\n)/g;

export function formatTextAsHtml(text) {
    return text.split(REGEX_URL_MATCHING)
        .map((part, i) => {
            if (part === '\n') return <br key={i} />;
            if (part.match(REGEX_URL_MATCHING)) {
                return <a key={i} href={part} target="_blank">{part}</a>;
            }
            return <span key={i}>{part}</span>;
        });
}
