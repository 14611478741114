import { Heading, Text, Flex } from "@radix-ui/themes";
import CheckIcon from "@/assets/icons/check.svg";
import { Badge, Button } from "@/components";
import { TopLayout } from "@/layouts";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase-globals";

export default function RegistrationCompletePage() {
    const { t } = useTranslation();

    function trackContinue() {
        logEvent(analytics, 'continue_basic_info')
    }

    return (
        <TopLayout>                  
            <Flex direction="column" gap="5" align="center" justify="center" style={{ height: '100%' }}>
                <Badge icon={CheckIcon} />
                <Heading size="6" align="center" mt="4">{ t('success_registration_title') }</Heading>                        
                <Text align="center">{ t('success_registration_subtitle') }</Text>                            
                <ol>
                    <li>{ t('success_registration_next_step_1') }</li>
                    <li>{ t('success_registration_next_step_2') }</li>
                    <li>{ t('success_registration_next_step_3') }</li>
                </ol>                
            </Flex>            
            <Text align="center" size="2" mb="2">{ t('success_registration_explanation') }</Text>                        
            <Button primary to='/basic-info/0' onClick={trackContinue}>{ t('action_get_started') }</Button>                
        </TopLayout>
    );
}
