import { Flex } from "@radix-ui/themes";
import { BackButton, Placeholder, ProgressBar } from "@/components";

export default function WizardProgressBar({ previousRoute, percentage }) {
    return (
        <Flex width="100%" justify="between"  align="center">
            { previousRoute && <BackButton to={ previousRoute } /> || <Placeholder /> }            
            <Flex width="100%" justify="center" align="center">
                <ProgressBar percentage={ percentage } />
            </Flex>
            <Placeholder />
        </Flex>
    );
}
