import { Box, Flex } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";

export default function NewMessages({ count, onClick }) {
    const { t } = useTranslation();
    
    if (count === 0) {
        return undefined;
    }

    const translationKey = count < 2 ? 'text_new_message_1' : count > 4 ? 'text_new_messages_5_or_more' : 'text_new_messages_2_4';

    return (
        <Flex justify="center" style={{ 
            position: 'fixed', bottom: '99px', left: 'var(--space-4)', right: 'var(--space-4)',                        
        }}>    
            <Box style={{                 
                backgroundColor: 'var(--gray-a11)',
                borderRadius: 'var(--radius-4)',
                padding: 'var(--space-4) var(--space-5)',
                color: '#fff',
                cursor: 'pointer'
            }} onClick={onClick}>
                {t('text_you_have')} {count} {t(translationKey)}
            </Box>
        </Flex>
    );
}
