
import "./birthday.scss";
import Inputmask from "inputmask";
import { useEffect, useRef } from "react";

export default function Birthday({ valueChange, ...props }) {
    const inputRef = useRef();

    useEffect(() => {
        Inputmask({ alias: "datetime", inputFormat: "dd/mm/yyyy", colorMask: "red" }).mask(inputRef.current);
    }, [])

    function onChange(event) {
        valueChange(event.target.value);
    }
        
    return <input className="birthday-input" ref={inputRef} placeholder="DD/MM/YYYY" onChange={onChange} {...props} />; // TODO: Better validation
}