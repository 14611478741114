import { Avatar as UIAvatar } from "@radix-ui/themes";
import UserIcon from "@/assets/icons/user.svg";


export default function Avatar({ user, size="5" }) {
    const gradient = getBackgroundGradient(user);
    return <UIAvatar 
        src={user.first_name == null ? UserIcon : undefined}
        fallback={user.first_name == null ? '' : user.first_name.charAt(0)} 
        size={size}
        radius="full" 
        style={{                                   
            backgroundImage: 'linear-gradient(' + gradient + ')'
        }} />
}

const gradients = [
    '25deg, #dce35b, #45b649',    
    '135deg, #8a2387, #e94057, #f27121',
    '45deg, #4e54c8, #8f94fb',
    '75deg, #fc4a1a, #f7b733',
    '135deg, #22c1c3, #fdbb2d',
    '135deg, #bdc3c7, #2c3e50',
    '135deg, #42275a, #734b6d',
    '135deg, #e9d362, #333333',
    '0deg, #4cb8c4, #3cd3ad',
    '45deg, #c31432, #240b36',
]

function getBackgroundGradient(user) {    
    return gradients[(user.id - 1) % gradients.length];    
}
