import { Link as UILink } from "@radix-ui/themes";
import { Link as RouterLink } from "react-router-dom";

export default function Link({ to, children, ...props }) {    
    return (
        <UILink asChild style={{ fontWeight: 600 }}>
            <RouterLink to={ to } onClick={props.onClick}>{ children }</RouterLink>
        </UILink>  
    );    
}
