import { useLogoutMutation } from "@/api/authApi";
import { Avatar } from "@/components";
import { DropdownMenu } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ProfileButton({ user, ...rest }) {
    const { t } = useTranslation();    
    const navigate = useNavigate();
    
    const [performLogout] = useLogoutMutation();

    async function logout() {
        await performLogout();
        navigate('/welcome');
    }

    const profilePosition = rest.topRightCorner ? { position: 'absolute', right: 'var(--space-5)', top: '36px', zIndex: 999 } : {};

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger>
                <div style={{ cursor: 'pointer', ...profilePosition }}>
                    <Avatar user={user} size="2" />
                    { rest.displayName && <span style={{ marginLeft: 'var(--space-3)' }}>{user.first_name}</span> }
                </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
                <DropdownMenu.Item onClick={logout}>{t('action_logout')}</DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}
