import { Badge, Link, Logo, Spinner } from "@/components";
import EmailIcon from "@/assets/icons/email.svg";
import { TopLayout } from "@/layouts";
import { Flex, Heading, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useVerifyEmailMutation } from "@/api/authApi";
import useDidMount from "@/hooks/use-did-mount";

export default function EmailVerifiedPage() {
    const { t } = useTranslation();

    const [verifyEmail, { isLoading, isError, error, isSuccess }] = useVerifyEmailMutation();
    const { code } = useParams();    
    const [ signUpAgain, setSignUpAgain ] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');    

    useDidMount(() => {
        verifyEmail(code);
    });

    useEffect(() => {
        if (!isError) return;
        if (error.status === 404) {    
            setErrorMessage(t('error_verification_code_404'));   
            setSignUpAgain(true);             
        } else if (error.status === 429) {
            setErrorMessage(t('error_too_many_attempts'));            
        } else {
            setErrorMessage(t('error_unexpected'));
            console.log("Unexpected response status: ", error);
        }        
    }, [isError, error]);

    if (isSuccess) {
        return <Navigate to='/' />;
    }
    
    return (
        <TopLayout>                    
            <Logo small />  
            <Flex direction="column" gap="5" align="center" justify="center" style={{ height: '100%' }}>                
                { isLoading && <>
                    <Spinner size={64} />
                    <Heading size="6" align="center" mt="4">{ t('verifying_email_title') }</Heading>                        
                    <Text align="center">{ t('verifying_email_subtitle') }</Text>                                                
                </> }                
                { isError && <>
                    <Badge icon={EmailIcon} />
                    <Heading size="6" align="center" mt="4">{ t('verifying_email_error_title') }</Heading>                        
                    <Text align="center">{ errorMessage } { signUpAgain && <Link to="/sign-in">{ t('action_sign_in_again') }</Link> }</Text>                                                
                </> }
            </Flex>            
        </TopLayout>
    );
}