import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './base';

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: customFetchBase,   
    tagTypes: ['Profile'], 

    endpoints: (builder) => ({
        getProfile: builder.query({
            query: () => ({
                url: '/profile',
                method: 'GET',             
            }),
            providesTags: ['Profile'],
        }),

        updateProfile: builder.mutation({
            query: (partialProfile) => ({
                url: '/profile',
                method: 'PATCH',                
                body: partialProfile,
            }),
            async onQueryStarted(profile, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(profileApi.util.updateQueryData('getProfile', undefined, (draft) => {
                        Object.assign(draft, profile);
                    }));
                } catch (error) {}
            }
        }),
    }),
})

export const {
    useGetProfileQuery,
    useUpdateProfileMutation,
    endpoints
} = profileApi;
