import { Button, WizardContent, WizardHeader } from "@/components";
import { TopLayout } from "@/layouts";
import { useTranslation } from "react-i18next";
import useWizard from "@/hooks/use-wizard";
import FullScreenLoader from "./full-screen-loader";
import { Box, Text } from "@radix-ui/themes";

// TODO: Make enter work
// TODO: Spinner on next step button
export default function WizardBase({ name, getHook, patchHook, steps, ...props }) {
    const { t } = useTranslation();
    const { isLoading, isValid, error, value, setValue, step, percentage, nextStep, previousRoute } = useWizard(name, getHook, patchHook, steps, props.fastForwardCompelteUrl);

    function onKeyPress(e) {
        if (e.key === 'Enter') {
            nextStep(); 
        }
    }

    if (isLoading) return <FullScreenLoader />;    

    return (
        <TopLayout>
            <WizardHeader previousRoute={previousRoute} percentage={percentage} name={step.translated_name ? step.translated_name : t(`wizard_${step.name}`)} subtitle={t(step.subtitle)} />
            <WizardContent>
                {step.content({ value, valueChange: setValue, onKeyPress: onKeyPress })}
                {error && <Box my="5" style={{textAlign: "center"}}><Text color="red">{t(error)}</Text></Box>}
            </WizardContent>
            <Button primary onClick={nextStep} disabled={!isValid}>{t('action_continue')}</Button>
        </TopLayout>
    );
}
