import { Flex, Heading, Text, Button } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";

export default function FullScreenLoadingError({ onRetry }) {
    const { t } = useTranslation();
    return (
        <Flex width="100%" height="100%" align="center" justify="center" direction="column" gap="3">            
            <Heading size="4">{ t('title_unable_to_load') }</Heading>
            <Text mb="3">{ t('text_check_connection') }</Text>
            <Button onClick={onRetry} size="3" radius="full">{ t('action_try_again') }</Button>            
        </Flex>        
    );
}
