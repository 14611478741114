import store from '@/store';
import { requestMessagingToken } from "@/firebase-globals";
import { endpoints as notificationEndpoints } from '../api/notificationsApi';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { login, logout } from '@/api/authSlice';
import { setAccessToken } from '@/api/base';
import i18n from '@/i18n';

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: login,
    effect: async (action, listenerApi) => await addMessagingToken()    
});

listenerMiddleware.startListening({
    actionCreator: logout,
    effect: async (action, listenerApi) => await removeMessagingToken()
});

async function addMessagingToken() {
    if (Notification.permission !== 'granted') return;
    await requestAndStoreMessagingToken();
}

export async function requestAndStoreMessagingToken() {
    const messagingToken = await requestMessagingToken();
    if (messagingToken != null) {
        store.dispatch(notificationEndpoints.addToken.initiate({ token: messagingToken, language: i18n.resolvedLanguage }))
    }    
}

async function removeMessagingToken() {
    if (Notification.permission !== 'granted') return;
    
    const messagingToken = await requestMessagingToken();
    if (messagingToken != null) {
        store.dispatch(notificationEndpoints.removeToken.initiate({ token: messagingToken }))
        setTimeout(() => setAccessToken(null));
    } else {   
        setAccessToken(null);
    }
}
