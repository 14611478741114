import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const VAPID_KEY = 'BLTBRG_uRaiX3d1fU2Nnt8HwlxoB6Uabx7tqaiRD1v6hPqmWsSNRgaHFy8RLL88GRvhJX7F1C2OQnvZwojLkYKo'

const firebaseConfig = {
  apiKey: "AIzaSyC9dLNhUuhjBNwse6pVZstMD5RPqopjDfQ",
  authDomain: "friendify-7f934.firebaseapp.com",
  projectId: "friendify-7f934",
  storageBucket: "friendify-7f934.appspot.com",
  messagingSenderId: "1056024347477",
  appId: "1:1056024347477:web:52e7ccf59fa1ded5ec65dc",
  measurementId: "G-H0276QK7N9"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export async function requestMessagingToken() {
  return await getToken(messaging, {vapidKey: VAPID_KEY});
}
