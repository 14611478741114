import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './base';
import { addMessage, addUnreadMessages } from './chatSlice';

export const chatApi = createApi({
    reducerPath: 'chatApi',
    baseQuery: customFetchBase,   
    tagTypes: ['Chats'], 

    endpoints: (builder) => ({
        getChats: builder.query({
            query: () => ({
                url: '/chats/',
                method: 'GET',             
            }),
            providesTags: ['Chats'],
            async onCacheEntryAdded(arg, { dispatch, getCacheEntry, cacheDataLoaded }) {                
                await cacheDataLoaded;                                
                getCacheEntry().data.results.forEach(chat => {
                    dispatch(addUnreadMessages({ chat_id: chat.id, unread_messages: chat.unread_messages }));
                });
              },
        }),

        getChat: builder.query({
            query: (chatId) => ({
                url: `/chats/${chatId}`,
                method: 'GET',             
            }),
        }),        

        sendMessage: builder.mutation({
            query: ({ chatId, text }) => ({
                url: `/chats/${chatId}/messages`,
                method: 'POST',
                body: { text },
            }),
        }),

        // TODO: Check proper invalidation with multiple different conversations
    }),
})

export const {
    useGetChatsQuery,    
    useGetChatQuery,
    useSendMessageMutation,
    endpoints
} = chatApi;


