import { Box, Flex } from "@radix-ui/themes";

export default function TopLayout({ children, px="5", py="7", maxWidth=true, gap="5", limitHeight=true }) {
    return (
        <Flex height="100%" width="100%" justify="center" align="center" px={px} py={py}>                                    
            <Flex align="center" direction="column" gap={gap} style={{ 
                width: '100%', 
                maxWidth: maxWidth ? '450px' : '100%',                
                maxHeight: limitHeight ? '850px': '100%',
                height: '100%',
                margin: '0 auto' 
            }}>
                { children }
            </Flex>            
        </Flex>
    );
}
