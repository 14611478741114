import { useEffect, useState } from "react";

function getScrollBottom(element) {
    if (!element) return null;    
    return element.scrollHeight - element.clientHeight - element.scrollTop;
}

function setScrollBottom(element, value) {
    if (!element) return;
    element.scrollTop = element.scrollHeight - element.clientHeight - value;
}

export default function useRememberScrollBottom(data, containerRef) {
    const [lastScrollPosition, setLastScrollPosition] = useState(null);        

    useEffect(() => {        
        const scrollBottom = getScrollBottom(containerRef.current);
        if (scrollBottom == null) {            
            return;
        }        

        if (lastScrollPosition == null) {                        
            return;
        }
                
        setScrollBottom(containerRef.current, lastScrollPosition);                

        // To prevent scrolling when data changes externally (no remember callback was called)
        setLastScrollPosition(null);
    }, [data]);
    
    return () => {        
        setLastScrollPosition(getScrollBottom(containerRef.current));        
    };
}
