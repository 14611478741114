import { IconButton  as UIIconButton  } from "@radix-ui/themes";
import { Link as RouterLink } from "react-router-dom";
import ArrowLeft from "@/assets/icons/arrow-left.svg";

export default function BackButton({ to }) {
    return (
        <UIIconButton asChild variant="ghost">
            <RouterLink to={to}>
                <img src={ArrowLeft} width="24" height="24" />
            </RouterLink>
        </UIIconButton>
    );
}
