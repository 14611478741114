import { ProgressBar, FullScreenProgressBar } from "@/components";
import { Heading, Text, Flex, Button } from "@radix-ui/themes";
import HeartIcon from "@/assets/icons/heart.svg";
import CrossIcon from "@/assets/icons/cross.svg";
import FullScreenLoader from "./full-screen-loader";
import { Navigate, useSearchParams } from "react-router-dom";
import i18n from "@/i18n";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { useEffect, useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase-globals";

const initialProgress = 10;
const requiredYes = 5;


export default function VotableContent({ getCounterHook, getUnvotedHook, voteHook, renderContent, completeUrl, id, ...props }) {
    const { t } = useTranslation();    
    const [searchParams, setSearchParams] = useSearchParams();

    const [voteContent] = voteHook();            

    const { data: counter } = getCounterHook();
    const { data: content , refetch } = getUnvotedHook({ language: i18n.resolvedLanguage, count: 1 });    

    const [swiper, setSwiper] = useState(null);  

    useEffect(() => {
        if (content && swiper) {
            setTimeout(() => swiper.slideTo(1, 0), 50);
        }
    }, [content]);

    if (!content || !counter) {
        return <FullScreenLoader />;
    }
    
    if (content.data.length == 0) {                
        const shouldFastForward = searchParams.get('ff') && props.fastForwardCompleteUrl;        
        return <Navigate to={shouldFastForward ? props.fastForwardCompleteUrl : completeUrl} />;        
    }

    const percentage = stepToPercentage(counter.no_votes, counter.yes_votes, content.total_count);        

    const handleVote = async (voteType) => {
        logEvent(analytics, `vote_${id}`, { content_id: content.data[0].id, answer: voteType });
        await voteContent({ contentId: content.data[0].id, voteType });        
        setSearchParams({});
        refetch();
    };

    function onSwipe(swipe) {                
        if (swipe.activeIndex === 0) {            
            setTimeout(() => handleVote('yes'), 300);
        } else if (swipe.activeIndex === 2) {
            setTimeout(() => handleVote('no'), 300);
        }
    }
    
    return (
        <div style={{ height: '100%', maxWidth: '1024px', margin: '0 auto' }}>
            <Flex direction="column" height="100%" width="100%">            
                { props.fullscreen && <FullScreenProgressBar percentage={ percentage } /> ||
                    <Flex mt="8" mb="6" justify="center" style={{zIndex: 3}} >
                        <ProgressBar percentage={ percentage } />
                    </Flex>                
                }
                
                <Swiper onSwiper={setSwiper} onActiveIndexChange={onSwipe} effect={'cards'} grabCursor={true} initialSlide={1} spaceBetween={0} slidesPerView={1} style={{ width: '100%', height: '100%' }}>
                    <SwiperSlide><YesSlide /></SwiperSlide>
                    <SwiperSlide>{ renderContent(content.data[0]) }</SwiperSlide>
                    <SwiperSlide><NoSlide /></SwiperSlide>
                </Swiper>

                <Flex direction="column" gap="3" mx="5" my="5">
                    { content.data[0].title && <Heading size="6">{ content.data[0].title }</Heading> }
                    { content.data[0].description && <Text size="2" mb="4">{ content.data[0].description }</Text> }
                    <Flex justify="between" style={{zIndex: 3}}>
                        <Action icon={CrossIcon} title={ t(`action_${props.actionPrefix || ''}no`) } onClick={() => handleVote('no')} /> 
                        <Action icon={HeartIcon} title={ t(`action_${props.actionPrefix || ''}yes`) } onClick={() => handleVote('yes')} />                    
                    </Flex>
                </Flex>            
            </Flex>
        </div>
    );
}

function YesSlide() {
    const { t } = useTranslation();
    return (
        <Flex justify="center" align="center" style={{ height: '100%', width: '100%', backgroundColor: '#498BFE', color: '#fff' }}>
            <Heading size="6">{ t('action_yes') }</Heading>
        </Flex>
    );
}

function NoSlide() {
    const { t } = useTranslation();
    return (
        <Flex justify="center" align="center" style={{ height: '100%', width: '100%', backgroundColor: '#FF5069', color: '#fff' }}>
            <Heading size="6">{ t('action_no') }</Heading>
        </Flex>
    );    
}

function Action(props) {
    return (
        <Button radius="full" size="4" style={{ height: '56px', fontWeight: '600', backgroundColor: '#fff', color: 'var(--gray-12)' }} onClick={props.onClick}>
            <img src={ props.icon } width="24" height="24" />
            { props.title }
        </Button>        
    );
}


function stepToPercentage(counterNo, counterYes, totalContent) {
    const noContribution = counterNo * (100 - initialProgress) / totalContent;
    const yesContribution = counterYes * (100 - noContribution - initialProgress) / requiredYes;    
    return initialProgress + yesContribution + noContribution;
}
