const DATE_REGEXP = /^\d{2}\/\d{2}\/\d{4}$/;

export function notEmptyString(value) {
    return value != null && (value + "").trim().length > 0;
}

export function validBirthday(value) {        
    if (!DATE_REGEXP.test(value)) {
        return false;
    }
        
    const dateArray = value.split("/");    
    const yyyymmdd = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;    

    const birthDate = new Date(yyyymmdd);    
    const today = new Date();

    if (isNaN(birthDate.getTime())) {
        return false;
    }

    var age = today.getFullYear() - birthDate.getFullYear();
    var month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;        
    }

    if (age < 18) {
        throw Error('error_underage');
    }

    return true;
}

export function minLength(minLength) {
    return (value) => {
        return value && value.length >= minLength;
    }
}

export function alwaysValid(_value) {
    return true;
}
